import React, { useState, useEffect } from "react";
import "../../assets/css/MyData.css"; // CSS 파일 임포트
import { ReactComponent as Icon1 } from "../../assets/svg/mydata1.svg";
import { ReactComponent as Icon2 } from "../../assets/svg/mydata2.svg";
import { ReactComponent as Icon3 } from "../../assets/svg/mydata3.svg";
import { ReactComponent as Icon4 } from "../../assets/svg/mydata4.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useStore from "../../store/useStore";
import tokenStore from "../../store/tokenStore";

function MyData1() {
  const [residentNo1, setResidentNo1] = useState("");
  const [residentNo2, setResidentNo2] = useState(""); // 실제 값 저장
  const [authCode, setAuthCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState(""); // 사용자 입력 인증번호
  const [isVerificationSent, setIsVerificationSent] = useState(false); // 인증번호 전송 여부
  const [selectedCarrier, setSelectedCarrier] = useState(""); // 통신사 선택 상태
  const user = useStore((state) => state.user);
  const { token, setToken, clearToken } = tokenStore();
  const [timeLeft, setTimeLeft] = useState(180);

  const navigate = useNavigate();

  const hashResidentNumber = (residentNumber) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(residentNumber);
    return crypto.subtle.digest("SHA-256", data).then((hashBuffer) => {
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");
      return hashHex;
    });
  };

  useEffect(() => {
    if (isVerificationSent && timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId); // 컴포넌트 언마운트 시 타이머 정리
    } else if (timeLeft === 0) {
      alert("인증 시간이 만료되었습니다. 다시 시도해주세요.");
    }
  }, [isVerificationSent, timeLeft]);

  const handleInputChange2 = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, ""); // 숫자만 허용
    setResidentNo2(value.slice(0, 7)); // 최대 7자리까지 입력 가능
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userCi = `${residentNo1}${residentNo2}`;
    console.log(userCi);
    try {
      const hashedUserCi = await hashResidentNumber(userCi);
      const response = await axios.get("/api/mydata/auth/authorize", {
        params: { userCi: hashedUserCi }, // 쿼리 파라미터로 주민등록번호 전달
      });
      setAuthCode(response.data); // authCode 상태 업데이트
      await fetchTokens(user.id, response.data); // 토큰 요청 함수 호출
    } catch (error) {
      console.error("API 요청 오류:", error);
    }
  };

  const fetchTokens = async (userId, authCode) => {
    try {
      const response = await axios.post("/api/mydata/auth/token", null, {
        params: { userId, authCode },
      });
      const { accessToken, refreshToken } = response.data;
      setToken({ accessToken, refreshToken });

      navigate("/myData2"); // 요청 성공 후 다음 페이지로 이동
    } catch (error) {
      console.error("토큰 요청 오류:", error);
    }
  };

  const handleCarrierChange = (e) => {
    setSelectedCarrier(e.target.value);
  };

  // 휴대폰 번호로 인증번호 전송
  const handlePhoneVerification = async () => {
    if (!phoneNumber || !selectedCarrier) {
      alert("통신사와 휴대폰 번호를 입력해주세요.");
      return;
    }
    try {
      const response = await axios.post("/api/mydata/send", {
        phoneNumber,
      });
      console.log(response.data);
      setIsVerificationSent(true);
      setTimeLeft(180);
    } catch (error) {
      console.error("인증 요청 실패:", error);
      alert("인증 요청 중 오류가 발생했습니다.");
    }
  };

  // 인증번호 검증
  const handleVerifyCode = async () => {
    navigate("/mydata2");
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");

    if (phoneNumber.length <= 3) return phoneNumber;
    if (phoneNumber.length <= 7)
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      7
    )}-${phoneNumber.slice(7, 11)}`;
  };

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhoneNumber);
  };

  const formatTime = (timeLeft) => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <div className="mydata-container">
      <div className="mydata-text">
        <h1>마이데이터 가입</h1>
        <span>내 계좌·대출·증권·포인트 보기</span>
      </div>
      <div className="icon-row">
        <div className="icon active">
          <div className="circle-container">
            <div className="big-circle">
              <div className="small-circle">
                <Icon1 />
              </div>
            </div>
          </div>
          <p>본인인증</p>
        </div>
        <div className="line"></div>
        <div className="icon">
          <div className="icon-circle">
            <div className="circle-container">
              <div className="big-circle">
                <div className="small-circle">
                  <Icon2 style={{ marginLeft: "7px" }} />
                </div>
              </div>
            </div>
          </div>
          <p>약관동의</p>
        </div>
        <div className="line"></div>
        <div className="icon">
          <div className="icon-circle">
            <div className="circle-container">
              <div className="big-circle">
                <div className="small-circle">
                  <Icon3 />
                </div>
              </div>
            </div>
          </div>
          <p>연결자산 선택</p>
        </div>
        <div className="line"></div>
        <div className="icon">
          <div className="icon-circle">
            <div className="circle-container">
              <div className="big-circle">
                <div className="small-circle">
                  <Icon4 />
                </div>
              </div>
            </div>
          </div>
          <p>가입완료</p>
        </div>
      </div>
      <div className="form-container">
        <h2>정보입력</h2>
        <form className="form" onSubmit={handleSubmit}>
          <label className="form-label">
            <h3>이름</h3>
            <input
              type="text"
              placeholder="이름을 입력하세요"
              style={{ marginLeft: "150px" }}
              className="input-text"
            />
          </label>
          <label className="form-label">
            <h3>주민등록번호</h3>
            <div
              className="resident-number-label"
              style={{ marginLeft: "80px" }}
            >
              <input
                type="text"
                placeholder="주민등록번호 앞 6자리"
                className="input-text"
                value={residentNo1}
                onChange={(e) => setResidentNo1(e.target.value.slice(0, 6))}
              />
              -
              <input
                type="password"
                placeholder="주민등록번호 뒤 7자리"
                className="input-text"
                style={{ backgroundColor: "#B3B3B3", color: "red" }}
                value={residentNo2}
                onChange={handleInputChange2}
              />
              <input type="hidden" value={residentNo2} />
            </div>
          </label>

          <label className="form-label">
            <h3>휴대폰번호</h3>
            <div
              className="phone-input-container"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "100px",
              }}
            >
              <select
                value={selectedCarrier}
                onChange={handleCarrierChange}
                className="carrier-select"
                style={{
                  marginRight: "10px",
                  width: "90px",
                  padding: "7px",
                  borderRadius: "5px",
                  marginBottom: "-1px",
                  fontSize: "15px",
                }}
              >
                <option value="skt">SKT</option>
                <option value="kt">KT</option>
                <option value="lg">LG U+</option>
                <option value="skt_free">알뜰폰(SKT)</option>
                <option value="kt_free">알뜰폰(KT)</option>
                <option value="lg_free">알뜰폰(LG U+)</option>
              </select>
              <input
                type="text"
                placeholder="휴대폰 번호를 입력하세요"
                style={{ flex: 1 }}
                className="input-text"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
              <button
                type="button"
                className="verification-button"
                onClick={handlePhoneVerification}
                style={{ marginLeft: "10px" }}
              >
                인증 요청
              </button>
            </div>
          </label>

          {isVerificationSent && (
            <div className="verification-section">
              <label className="form-label">
                <h3>인증번호</h3>
                <div
                  className="verification-input-container"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "110px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="인증번호를 입력하세요"
                    style={{ flex: 1 }}
                    className="input-text"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                  />
                  <button
                    type="button"
                    className="verification-button"
                    onClick={handleVerifyCode}
                    style={{ marginLeft: "10px" }}
                  >
                    시간 연장
                  </button>
                  {/* 타이머 표시 */}
                  <div
                    className="timer"
                    style={{
                      marginLeft: "10px",
                      fontSize: "13px",
                      fontWeight: "bold",
                      color: "red",
                    }}
                  >
                    {formatTime(timeLeft)}
                  </div>
                </div>
              </label>
            </div>
          )}

          <button type="submit" className="myDataButton">
            확인
          </button>
        </form>
      </div>
    </div>
  );
}

export default MyData1;
