import React, { useState } from "react";
import "../../assets/css/MyData.css"; // CSS 파일 임포트
import { ReactComponent as Icon1 } from "../../assets/svg/mydata1.svg";
import { ReactComponent as Icon2 } from "../../assets/svg/mydata2.svg";
import { ReactComponent as Icon3 } from "../../assets/svg/mydata3.svg";
import { ReactComponent as Icon4 } from "../../assets/svg/mydata4.svg";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

function MyData2() {
  const navigate = useNavigate();
  const [allChecked, setAllChecked] = useState(false);
  const [term2Checked, setTerm2Checked] = useState(false);
  const [term3Checked, setTerm3Checked] = useState(false);
  const [term4Checked, setTerm4Checked] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false); // 모달 상태
  const [activeTab, setActiveTab] = useState(1);

  // 전체 동의 체크박스 변경 시
  const handleAllCheck = () => {
    const newCheckedState = !allChecked;
    setAllChecked(newCheckedState);
    setTerm2Checked(newCheckedState);
    setTerm3Checked(newCheckedState);
    setTerm4Checked(newCheckedState);
    setModalIsOpen(true); // 전체 동의 시 모달을 띄움
  };

  // 개별 체크박스 변경 시
  const handleIndividualCheck = (setCheckFunc, currentCheckState) => {
    setCheckFunc(!currentCheckState);

    // 개별 항목이 모두 체크된 경우 전체 동의도 체크
    if (!currentCheckState && term2Checked && term3Checked && term4Checked) {
      setAllChecked(true);
    } else if (currentCheckState && allChecked) {
      // 하나라도 해제되면 전체 동의 체크 해제
      setAllChecked(false);
    }
  };

  const goTo2 = () => {
    navigate("/myData3");
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const renderAgreementContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <p style={{ padding: "15px", lineHeight: "1.5", fontSize: "13px" }}>
            제 1조 목적 이 약관은 하나택스 주식회사(이하 ‘회사’라 합니다.)에서
            제공하는 마이데이터 서비스(이하 ‘서비스’라 합니다.)를 이용함에 있어
            회사와 이용자의 권리, 의무 및 책임사항 기타 필요한 사항을 규정하는데
            목적이 있습니다. <br />
            <br />제 2조 용어의 정의 이 약관에서 사용하는 용어는 아래와
            같습니다. <br />① 서비스 : 이용자의 동의 하에 타사의 자산정보를
            수집하거나 직접 입력한 금융정보를 확인할 수 있게 하는 서비스,
            이용자의 금융거래내역 정보와 금융상품별 혜택 정보를 통합조회하는
            서비스, 각종 금융 컨텐츠 등으로 구성됩니다. <br />② 이용자 : 회사의
            고객으로 서비스 이용약관에 동의하고 서비스를 이용하는 고객을
            말합니다. <br />③ 인증매체 : 은행사, 증권사, 보험사, 카드사, 통신사,
            공공기관 등으로부터 금융데이터를 조회하기 위해 필요한 공동인증서,
            사설인증서, ID 등 인증수단을 의미합니다.
            <br /> ④ 해지 : 서비스 이용종료를 의미합니다. MY자산(마이데이터)
            서비스의 종료만 의미하며 회사의 다른 서비스는 정상적으로 이용할 수
            있습니다. <br />⑤ 이 약관에서 정하지 않은 용어는 관련 법령 및
            금융분야 마이데이터 서비스 가이드라인에서 정하는 용어를 준용합니다.{" "}
            <br />
            <br />
            <br />제 3조 서비스 이용계약의 성립 <br />① 고객이 서비스 이용을
            위해서는 본 약관과 m.Club 서비스 이용에 동의하고 회사가 정한 양식에
            따라 서비스 이용신청을 진행해야 합니다.
            <br /> ② 고객은 서비스 신청 시 본 약관과 ’알고하는 동의 및
            전송요구의 원칙’에 따른 ‘개인(신용)정보 전송요구’와 ‘개인(신용)정보
            수집·이용’에 동의해야 하며, 서비스 화면의 동의 버튼을 선택하면 각
            동의 항목에 동의한 것으로 간주 합니다.
            <br /> ③ 고객의 서비스 이용신청에 대하여 회사가 승낙함으로써
            이용계약이 성립하지만, 회사는 다음의 경우 이용자의 서비스 이용신청을
            승낙하지 않을 수 있습니다. 1. 고객이 타인의 정보를 이용하여 신청한
            경우 2. 고객이 허위의 정보를 이용했거나, 회사가 요청한 내용을
            기재하지 않은 경우 3. 회사의 PC, 모바일 서비스를 불법적 또는
            비정상적으로 사용하거나 그러한 목적으로 신청한 경우 4. 기타 회사가
            정한 이용신청 요건에 맞지 않을 경우 <br />④ 회사는 서비스 관련
            설비의 여유가 없거나, 기술상, 업무상, 재정적 문제가 있을 경우 서비스
            이용신청을 유보할 수 있습니다.
            <br /> ⑤ 서비스 이용계약은 회사가 가입 완료를 확인한 시점에
            성립합니다.
            <br /> ⑥ 회사는 서비스 이용과 관련하여 이용자에게 별도의 수수료를
            요구하거나 수취하지 않습니다. <br />
            <br />제 4조 서비스 내용 <br />① 이용자 동의 하에 이용자 명의의
            자산정보 및 금융정보를 종합하여 제공합니다. 1. 자산통합조회 :
            이용자가 본인의 은행, 증권사의 계좌를 등록하여 금융정보를
            통합조회하는 서비스 2. 연금통합조회 : 이용자가 본인이 가입한
            연금자산을 통합조회하는 서비스 3. 보험통합조회 : 이용자가 본인이
            가입한 보험자산을 통합조회하는 서비스 4. 소비통합조회 : 이용자가
            본인의 카드 등 거래내역과 카드혜택정보를 통합조회하는 서비스 <br />②
            회사는 관련 법령의 변경, 정책, 업무수행상 필요에 따라 서비스의 일부
            또는 전부를 중단, 변경, 추가할 수 있습니다.
            <br /> ③ 회사는 다음의 경우 해당 사유가 해소 될 때까지 이용자의
            서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 1.
            천재지변, 전시, 사변 또는 이에 준하는 국가비상사태 등 불가항력적인
            사유가 있는 경우 2. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로
            정상적인 서비스 이용에 지장이 있는 경우 3. 데이터 제공 기관의 전산,
            서비스 제한사항 등이 발생할 경우 4. 서비스 제공 설비 또는 시스템의
            재정비, 보수 등의 경우
            <br />
            <br /> 제 5조 서비스 이용계약의 해지
            <br />① 이용자는 회사의 PC, 모바일 서비스의 해지 기능을 통해 서비스
            이용계약을 해지할 수 있으며, 회사는 관련 법령 등에서 정하는 바에
            따라 이를 즉시 처리해야 합니다.
            <br /> ② 서비스 이용계약의 해지는 MY자산(마이데이터) 서비스의 해지
            만을 의미하며 하나택스의 타서비스 이용해지와는 별도로 취급합니다.{" "}
            <br />③ 이용계약을 해지할 경우 서비스와 관련된 이용자의 모든
            데이터는 파기됩니다. <br />④ 회사는 다음의 경우 이용자의 이용 계약을
            해지하거나 서비스를 중단할 수 있습니다. <br />
            1. 범죄행위와 관련되었을 경우 <br />
            2. 타인의 정보를 이용한 경우 <br />
            3. 허위의 정보를 이용한 경우 <br />
            4. 회사의 PC, 모바일 서비스를 불법적 또는 비정상적으로 사용한 경우
            <br />
            5. 서비스의 운영을 방해한 경우 <br />
            6. 서비스를 통해 얻은 정보를 회사의 동의 없이 복제, 유통하거나
            상업적으로 이용한 경우 <br />
            <br />제 6조 인증매체 및 개인(신용)정보의 수집, 관리 <br />① 회사는
            법령에서 정한 경우를 제외하고는 서비스를 제공하며 습득한 이용자의
            개인정보, 자산정보, 인증매체 정보를 이용자의 동의 없이 타인에게
            제공하거나 업무 외 목적으로 사용할 수 없습니다. <br />② 이용자는
            인증매체의 도용, 위조, 변조를 방지하기 위해 주의를 기울여야 합니다.
            회사는 이용자의 귀책사유로 인해 이용자의 인증매체가 도용, 위조, 변조
            됨으로 인하여 이용자에게 손해가 발생할 경우 이에 대해 책임을
            부담하지 않습니다. 다만, 회사의 귀책사유가 있을 경우 그 책임의 일부
            또는 전부를 부담할 수 있습니다. <br />
            <br />제 7조 책임의 한계 <br />① 회사는 이용자의 고의 또는 과실로
            인한 서비스 중지, 장애가 발생하더라도 그로 인한 손해에 대해 책임을
            부담하지 않습니다. 다만, 회사의 귀책사유가 있을 경우 그 책임의 일부
            또는 전부를 부담할 수 있습니다. <br />② 기간통신사업자의
            전기통신서비스를 중지하거나 정상적으로 제공하지 않아 이용자에게
            손해가 발생한 경우 회사는 그 책임을 부담하지 않습니다. <br />③
            회사는 이용자가 게시 또는 장성한 정보, 자료의 신뢰도, 정확성 등에
            대해 책임지지 않습니다. <br />④ 회사는 서비스를 통해 얻은 정보,
            자료, 정확성에 대해 책임지지 않으며, 서비스를 통해 이용자가 기대하는
            손익이나 서비스를 통해 얻은 자료로 인한 손해에 관해 책임지지
            않습니다.
            <br /> ⑤ 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여
            서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임을 부담하지
            않습니다. <br />
            <br />제 8조 기타 정보제공 및 안내 <br />① 회사는 이용자에게 다양한
            정보를 온라인, 모바일 상으로 게시하거나 이메일, SMS/LMS/MMS 메시지,
            전화, 푸시 등으로 이용자에게 제공할 수 있으며 이용자는 관련 법령에
            따른 거래관련 정보, 고객센터 답변 등을 제외한 정보의 전송에 대해서는
            거절의 의사표시를 할 수 있습니다. <br />② 회사는 사전동의를 한
            이용자에 한하여 이벤트, 뉴스레터, 관련정보 및 칼럼, 제휴사의
            상품소개 등 정보를 메일, SMS/LMS/MMS 메시지, 전화, 푸시 등으로
            이용자에게 제공할 수 있습니다. <br />
            <br />제 9조 약관 적용의 우선 순위
            <br /> ① 회사와 이용자 사이에 개별적으로 합의한 사항이 이 약관에
            정한 사항과 다를 때에는 그 합의 사항을 이 약관에 우선하여
            적용합니다.
            <br />② 이 약관에서 정하지 않은 사항은 회사의 전자금융거래서비스
            약관, 전자금융거래 이용에 관한 기본약관 등의 규정을 준용합니다. 제
            10조 분쟁의 해결 <br />① 회사와 이용자는 서비스와 관련한 분쟁을
            원만하게 해결하기 위해 필요한 모든 노력을 해야 합니다. <br />②
            1항에도 불구하고 분쟁으로 인하여 소송이 제기될 경우에는 회사의
            소재지를 관할하는 법원을 관할 법원으로 합니다. <br />③ 회사와
            이용자간에 제기된 전자거래 소송에는 대한민국법을 준거법으로 합니다.
          </p>
        );
      case 2:
        return (
          <p style={{ padding: "15px", lineHeight: "1.5", fontSize: "13px" }}>
            제 1조 목적 이 약관은 하나택스 주식회사(이하 ‘회사’라 합니다.)에서
            제공하는 마이데이터 서비스(이하 ‘서비스’라 합니다.)를 이용함에 있어
            회사가 이용자의 개인정보를 제3자에게 제공함에 대한 동의사항을
            규정하는데 목적이 있습니다. <br />
            <br />제 2조 개인정보 제공의 범위 <br />① 회사는 아래와 같은 목적을
            위해 이용자의 개인정보를 제3자에게 제공할 수 있습니다.
            <br /> 금융거래 정보의 종합 조회: 은행, 증권사, 보험사, 카드사
            등에서 제공된 금융 거래 내역, 계좌 정보 및 자산 정보를 종합 조회하기
            위함 <br />
            금융상품 추천: 이용자의 금융 정보를 기반으로 적합한 금융 상품을
            추천하기 위함
            <br /> 금융 리스크 관리: 이용자의 금융 자산 및 거래 내역을 분석하여
            금융 리스크를 관리하기 위함 기타 법령에서 정한 목적 <br />② 회사가
            제공하는 개인정보 항목은 다음과 같습니다.
            <br /> 금융 계좌 정보: 계좌번호, 잔액, 거래 내역 <br />
            신용 정보: 신용 등급, 대출 이력 보험 정보: 보험 가입 내역, 보장 내용
            기타 금융 거래와 관련된 정보
            <br />
            <br /> 제 3조 개인정보 제공의 대상 <br />① 회사는 이용자의
            개인정보를 다음의 제3자에게 제공할 수 있습니다. 은행, 증권사,
            보험사, 카드사 등 금융기관 관련 법령에 따라 요구되는 공공기관 및
            정부 기관 제휴된 금융 상품 제공자 및 관련 서비스 제공자 <br />②
            개인정보 제공 대상자는 법령에 따라 개인정보 보호의무를 성실히
            이행하며, 제공된 개인정보는 본 약관에 명시된 목적 외의 용도로
            사용하지 않습니다. <br />
            <br />제 4조 개인정보 제공의 기간
            <br /> ① 이용자의 개인정보는 서비스 이용 기간 동안 보유하며, 서비스
            이용 계약 종료 시 제공된 개인정보는 관련 법령에 따라 일정 기간
            보관된 후 안전하게 파기됩니다. <br />② 관련 법령에서 요구하는 경우,
            개인정보는 법령이 정한 기간 동안 보유할 수 있습니다. <br />
            <br />제 5조 개인정보 제공에 대한 동의 철회
            <br /> ① 이용자는 언제든지 개인정보 제공에 대한 동의를 철회할 수
            있습니다. 동의 철회를 원할 경우, 회사의 고객센터를 통해 요청할 수
            있으며, 회사는 관련 법령에서 정한 절차에 따라 이를 즉시 처리합니다.
            <br />② 개인정보 제공 동의 철회 시, 일부 서비스 이용에 제한이 있을
            수 있습니다. <br />
            <br />제 6조 책임의 한계 <br />① 회사는 이용자의 개인정보 제공으로
            인해 발생할 수 있는 피해에 대해 관련 법령에서 정한 범위 내에서
            책임을 집니다. 다만, 이용자의 고의 또는 과실로 인한 피해에 대해서는
            책임을 지지 않습니다. <br />② 회사는 개인정보 제공 대상자가
            개인정보를 불법적으로 이용하거나 관리 소홀로 인해 발생한 피해에
            대해서는 책임을 지지 않습니다. <br />
            <br />제 7조 기타
            <br /> ① 본 약관에 명시되지 않은 사항에 대해서는 개인정보 보호법,
            정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령을
            따릅니다. <br />② 본 약관은 관련 법령의 개정 또는 회사의 정책 변경에
            따라 변경될 수 있으며, 변경될 경우 사전에 공지합니다.
            <br /> <br /> 제 8조 분쟁 해결 <br />① 회사와 이용자는 개인정보
            제공과 관련된 분쟁을 원만히 해결하기 위해 성실히 노력해야 합니다.{" "}
            <br />② 분쟁이 발생할 경우, 회사의 소재지를 관할하는 법원을 관할
            법원으로 합니다.
          </p>
        );
      case 3:
        return (
          <p style={{ padding: "15px", lineHeight: "1.5", fontSize: "13px" }}>
            제 1조 목적 이 약관은 하나택스 주식회사(이하 ‘회사’라 합니다.)에서
            제공하는 마이데이터 서비스(이하 ‘서비스’라 합니다.)를 이용하기 위해
            필요한 인증 수단을 제공하고 관리하는 것에 대한 동의사항을 규정하는데
            목적이 있습니다.
            <br />
            <br /> 제 2조 인증 제공의 범위 <br />① 회사는 이용자의 서비스 이용을
            위해 필요한 인증 수단을 제공 및 관리하며, 인증 제공을 위한 개인정보
            항목은 다음과 같습니다. 인증 수단: 공동인증서, 사설인증서, 휴대폰
            인증, 신용카드 인증 등 인증 정보: 이름, 생년월일, 전화번호, 이메일
            주소, 주민등록번호 앞자리 등 추가 정보: 이용자가 선택한 금융기관의
            인증 정보(계좌번호, 카드번호 등)
            <br /> ② 이용자의 인증 수단은 이용자의 금융 데이터를 안전하게
            조회하고, 제공받기 위해 사용되며, 인증 과정에서 수집된 정보는 오직
            인증 목적으로만 사용됩니다. <br />
            <br />제 3조 인증 제공의 대상 <br />① 회사는 이용자의 인증 제공을
            위해 다음의 기관 및 제3자에게 인증 정보를 제공할 수 있습니다. 은행,
            증권사, 보험사, 카드사 등 금융기관 인증 관련 서비스 제공자(예:
            공동인증서 발급 기관, 통신사 등) 관련 법령에 따라 요구되는 공공기관
            및 정부기관 <br />② 제공된 인증 정보는 본 약관에서 정한 목적 외의
            용도로 사용되지 않으며, 인증 제공 대상자는 관련 법령에 따라 인증
            정보를 보호할 의무를 가집니다. <br />
            <br />제 4조 인증 정보의 사용 및 보관 보관 <br />① 회사는 인증 절차
            완료 후에도 법령에서 정한 기간 동안 이용자의 인증 정보를 보관할 수
            있습니다. <br />② 이용자가 서비스 이용을 해지하거나 개인정보 제공
            동의를 철회할 경우, 회사는 이용자의 인증 정보를 관련 법령에서 정한
            절차에 따라 파기합니다. <br />
            <br />제 5조 인증 제공에 대한 동의 철회 <br />① 이용자는 언제든지
            인증 제공에 대한 동의를 철회할 수 있습니다. 동의 철회를 원하는 경우,
            회사의 고객센터를 통해 요청할 수 있으며, 회사는 관련 법령에서 정한
            절차에 따라 이를 처리합니다. <br />② 동의 철회 시, 일부 서비스
            이용에 제한이 있을 수 있습니다. 예를 들어, 금융기관의 데이터 조회가
            제한될 수 있습니다. <br />
            <br />제 6조 인증 정보의 관리 및 책임 <br />① 회사는 인증 제공 시
            이용자의 개인정보 보호를 위해 암호화 등 적절한 보안 조치를 취합니다.
            <br />② 이용자는 인증 수단의 도용, 분실, 위조, 변조 등에 대해 주의할
            책임이 있으며, 이에 대한 관리 소홀로 인해 발생하는 손해에 대해
            회사는 책임을 지지 않습니다.
            <br /> ③ 회사의 귀책사유로 인해 인증 수단이 도용되거나 유출된 경우,
            회사는 이에 대해 관련 법령에서 정한 범위 내에서 책임을 집니다.{" "}
            <br />
            <br />제 7조 책임의 한계 <br />① 회사는 이용자가 인증 과정 중 본인의
            과실로 인해 발생한 손해에 대해서는 책임을 지지 않습니다. ②
            기간통신사업자의 전기통신 서비스 중단이나 오류로 인해 인증이 제대로
            이루어지지 않을 경우 회사는 그에 따른 책임을 부담하지 않습니다.{" "}
            <br />③ 회사는 인증 서비스 제공을 위한 외부 기관(인증서 발급 기관
            등)의 문제로 인해 발생하는 손해에 대해 책임을 지지 않습니다.
            <br /> <br /> 제 8조 기타 <br />① 본 약관에 명시되지 않은 사항에
            대해서는 개인정보 보호법, 정보통신망 이용촉진 및 정보보호 등에 관한
            법률 등 관련 법령을 따릅니다. <br />② 본 약관은 관련 법령의 개정
            또는 회사의 정책 변경에 따라 변경될 수 있으며, 변경될 경우 사전에
            공지합니다. <br />
            <br />제 9조 분쟁 해결 <br />① 회사와 이용자는 인증 제공과 관련된
            분쟁을 원만히 해결하기 위해 성실히 노력해야 합니다. <br />② 분쟁이
            발생할 경우, 회사의 소재지를 관할하는 법원을 관할 법원으로 합니다.
          </p>
        );
      default:
        return null;
    }
  };

  return (
    <div className="mydata-container">
      <div className="mydata-text">
        <h1>마이데이터 가입</h1>
        <span>내 계좌·대출·증권·포인트 보기</span>
      </div>
      <div className="icon-row">
        <div className="icon">
          <div className="circle-container">
            <div className="big-circle">
              <div className="small-circle">
                <Icon1 />
              </div>
            </div>
          </div>
          <p>본인인증</p>
        </div>
        <div className="line"></div>
        <div className="icon active">
          <div className="icon-circle">
            <div className="circle-container">
              <div className="big-circle">
                <div className="small-circle">
                  <Icon2 style={{ marginLeft: "7px" }} />
                </div>
              </div>
            </div>
          </div>
          <p>약관동의</p>
        </div>
        <div className="line"></div>
        <div className="icon">
          <div className="icon-circle">
            <div className="circle-container">
              <div className="big-circle">
                <div className="small-circle">
                  <Icon3 />
                </div>
              </div>
            </div>
          </div>
          <p>연결자산 선택</p>
        </div>
        <div className="line"></div>
        <div className="icon">
          <div className="icon-circle">
            <div className="circle-container">
              <div className="big-circle">
                <div className="small-circle">
                  <Icon4 />
                </div>
              </div>
            </div>
          </div>
          <p>가입완료</p>
        </div>
      </div>
      <div className="mydata2-container">
        <h2>
          자산을 연결하려면 <br />
          아래 내용에 동의해 주세요
        </h2>
        <div className="agreement-product-section">
          <div className="agreement-product-header">
            <label>
              <input
                type="checkbox"
                name="allCheck"
                checked={allChecked}
                onChange={handleAllCheck}
              />{" "}
              전체동의
            </label>
          </div>
          <ul className="agreement-product-list">
            <li>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label>
                  <input
                    type="checkbox"
                    name="term2"
                    checked={term2Checked}
                    onChange={() =>
                      handleIndividualCheck(setTerm2Checked, term2Checked)
                    }
                  />{" "}
                  <span style={{ color: "#099A96", fontWeight: "bold" }}>
                    [필수]
                  </span>{" "}
                  개인(신용)정보 수집이용 동의
                </label>
                <button
                  style={{
                    backgroundColor: "#fffcfc",
                    border: "1px solid #898989",
                    borderRadius: "5px",
                    color: "#898989",
                    padding: "5px",
                    fontFamily: "Pretendard-Regular",
                  }}
                >
                  자세히 보기
                </button>
              </div>
            </li>
            <li>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label>
                  <input
                    type="checkbox"
                    name="term3"
                    checked={term3Checked}
                    onChange={() =>
                      handleIndividualCheck(setTerm3Checked, term3Checked)
                    }
                  />{" "}
                  <span style={{ color: "#099A96", fontWeight: "bold" }}>
                    [필수]
                  </span>{" "}
                  개인(신용)정보 제공 동의
                </label>
                <button
                  style={{
                    backgroundColor: "#fffcfc",
                    border: "1px solid #898989",
                    borderRadius: "5px",
                    color: "#898989",
                    padding: "5px",
                    fontFamily: "Pretendard-Regular",
                  }}
                >
                  자세히 보기
                </button>
              </div>
            </li>
            <li>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label>
                  <input
                    type="checkbox"
                    name="term4"
                    checked={term4Checked}
                    onChange={() =>
                      handleIndividualCheck(setTerm4Checked, term4Checked)
                    }
                  />{" "}
                  <span style={{ color: "#099A96", fontWeight: "bold" }}>
                    [필수]
                  </span>{" "}
                  인증 제공 동의
                </label>
                <button
                  style={{
                    backgroundColor: "#fffcfc",
                    border: "1px solid #898989",
                    borderRadius: "5px",
                    color: "#898989",
                    padding: "5px",
                    fontFamily: "Pretendard-Regular",
                  }}
                >
                  자세히 보기
                </button>
              </div>
            </li>
          </ul>
        </div>
        <div className="asset-button-box">
          <button className="mydata-asset-button" onClick={goTo2}>
            다음
          </button>
        </div>
      </div>

      {/* Modal Component */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="약관 보기 모달"
        className="modal-agree"
        overlayClassName="overlay-modal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <span
              style={{
                display: "flex",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              약관 보기
            </span>
          </div>

          <div className="modal-agreement-box">
            <div
              className={`tab-agreement ${activeTab === 1 ? "selected1" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              개인(신용)정보 수집이용 동의
            </div>
            <div
              className={`tab-agreement ${activeTab === 2 ? "selected1" : ""}`}
              onClick={() => setActiveTab(2)}
            >
              개인(신용)정보 제공 동의
            </div>
            <div
              className={`tab-agreement ${activeTab === 3 ? "selected1" : ""}`}
              onClick={() => setActiveTab(3)}
            >
              인증 제공 동의
            </div>
          </div>

          <div className="modal-body1">{renderAgreementContent()}</div>
          <div className="modal-footer">
            <button
              className="modal-confirm-btn1"
              onClick={closeModal}
              style={{ marginTop: "20px" }}
            >
              동의
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MyData2;
