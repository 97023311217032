import React, { useState } from "react";
import "../../../assets/css/Product.css"; // Custom CSS file
import { BeatLoader } from "react-spinners";
import { ReactComponent as Char } from "../../../assets/svg/hana-char.svg";

function InterestCal() {
  const [activeTab, setActiveTab] = useState("예금");
  const [subTab, setSubTab] = useState("월납입액기준");
  const [depositAmount, setDepositAmount] = useState("1000"); // 예치금
  const [goalAmount, setGoalAmount] = useState("5000"); // 목표 금액 기준용 상태

  const [period, setPeriod] = useState("24"); // 예치 기간
  const [interestRate, setInterestRate] = useState("4"); // 금리
  const [interestType, setInterestType] = useState("단리"); // 이자 방식
  const [taxType, setTaxType] = useState("일반"); // 이자 과세 방식
  const [preTaxInterest, setPreTaxInterest] = useState("0"); // 세전 이자
  const [taxAmount, setTaxAmount] = useState("0"); // 이자 과세
  const [afterTaxReturn, setAfterTaxReturn] = useState("0"); // 실수령액
  const [afterTaxYield, setAfterTaxYield] = useState("0"); // 세후 수익률
  const [monthlyAmount, setMonthlyAmount] = useState("1000"); // 적금용 월 납입금액
  const [savingPeriod, setSavingPeriod] = useState("12"); // 적금용 납입기간
  const [savingInterestRate, setSavingInterestRate] = useState("1"); // 적금용 금리

  const [isLoading, setIsLoading] = useState(false);

  const handleDepositChange = (e) => {
    setDepositAmount(e.target.value.replace(/,/g, "")); // 숫자만 허용
  };

  const handleSubTabChange = (tab) => {
    setSubTab(tab);
  };

  const handlePeriodChange = (e) => {
    setPeriod(e.target.value);
  };

  const handleInterestChange = (e) => {
    setInterestRate(e.target.value);
  };

  const handleInterestTypeChange = (e) => {
    setInterestType(e.target.value);
  };

  const handleTaxTypeChange = (e) => {
    setTaxType(e.target.value);
  };

  const handleMonthlyAmountChange = (e) => {
    setMonthlyAmount(e.target.value);
  };

  const handleSavingPeriodChange = (e) => {
    setSavingPeriod(e.target.value);
  };

  const handleSavingInterestChange = (e) => {
    setSavingInterestRate(e.target.value);
  };

  // 예금 계산 로직
  const calculateDepositInterest = () => {
    setIsLoading(true);
    setTimeout(() => {
      const depositInWon = parseFloat(depositAmount) * 10000; // 예치금을 만원 -> 원 단위로 변환
      const rate = parseFloat(interestRate) / 100;
      const time = parseFloat(period) / 12; // 기간을 년 단위로 변환

      let preTax = 0;
      let taxRate = 0;

      // 이자 구분에 따른 계산
      if (interestType === "단리") {
        preTax = depositInWon * rate * time; // 세전 이자
      } else if (interestType === "복리") {
        preTax = depositInWon * Math.pow(1 + rate / 12, period) - depositInWon; // 세전 이자
      }

      // 이자 과세에 따른 세금률
      if (taxType === "일반") {
        taxRate = 0.154; // 15.4%
      } else if (taxType === "세금 우대") {
        taxRate = 0.095; // 9.5%
      } else if (taxType === "비과세") {
        taxRate = 0; // 0%
      }

      const tax = preTax * taxRate; // 세금 계산
      const afterTax = preTax - tax; // 세후 이자

      // 실수령액 계산
      const totalReturn = depositInWon + afterTax; // 원금 + 세후 이자
      const afterTaxYield = (afterTax / depositInWon) * 100; // 세후 수익률

      // 원금도 원 단위로 업데이트
      setDepositAmount(depositInWon.toFixed(0)); // 원금 업데이트
      setPreTaxInterest(preTax.toFixed(0)); // 세전 이자 업데이트
      setTaxAmount(tax.toFixed(0)); // 세금 업데이트
      setAfterTaxReturn(totalReturn.toFixed(0)); // 실수령액 업데이트
      setAfterTaxYield(afterTaxYield.toFixed(2)); // 세후 수익률 업데이트
      setIsLoading(false); // 로딩 종료
    }, 2000); // 2초 후에 계산 완료
  };

  // 적금 (월납입액 기준) 계산 로직
  const calculateSavingInterest = () => {
    setIsLoading(true);
    setTimeout(() => {
      const monthly = parseFloat(monthlyAmount) * 10000; // 월 납입금(만원 -> 원)
      const time = parseFloat(savingPeriod); // 납입 기간(개월 단위)
      const deposit = monthly * time; // 총 원금(월 납입금 * 납입 기간)
      const rate = parseFloat(savingInterestRate) / 100;

      setDepositAmount(deposit.toFixed(0)); // 원금 설정

      let preTax = 0;
      let taxRate = 0;

      // 이자 구분에 따른 계산
      if (interestType === "단리") {
        preTax = ((monthly * (time * (time + 1))) / (2 * 12)) * rate; // 단리 적금 계산
      } else if (interestType === "복리") {
        preTax = monthly * Math.pow(1 + rate / 12, time) - monthly; // 복리 적금 계산
      }

      // 이자 과세에 따른 세금률
      if (taxType === "일반") {
        taxRate = 0.154; // 15.4%
      } else if (taxType === "세금 우대") {
        taxRate = 0.095; // 9.5%
      } else if (taxType === "비과세") {
        taxRate = 0; // 0%
      }

      const tax = preTax * taxRate; // 세금 계산
      const afterTax = preTax - tax; // 세후 이자

      // 실수령액 계산
      const totalReturn = monthly * time + afterTax; // 원금 + 세후 이자
      const afterTaxYield = (afterTax / (monthly * time)) * 100; // 세후 수익률

      setPreTaxInterest(preTax.toFixed(0)); // 세전 이자 업데이트
      setTaxAmount(tax.toFixed(0)); // 세금 업데이트
      setAfterTaxReturn(totalReturn.toFixed(0)); // 실수령액 업데이트
      setAfterTaxYield(afterTaxYield.toFixed(2)); // 세후 수익률 업데이트
      setIsLoading(false); // 로딩 종료
    }, 2000); // 2초 후에 계산 완료
  };

  // 적금 (목표금액 기준) 계산 로직
  const calculateGoalAmountInterest = () => {
    setIsLoading(true);
    setTimeout(() => {
      const goal = parseFloat(goalAmount) * 10000; // 목표 금액 (만원 -> 원)
      const rate = parseFloat(savingInterestRate) / 100;
      const time = parseFloat(savingPeriod); // 납입 기간(개월 단위)

      let monthlyDeposit = 0;
      let preTax = 0;
      let taxRate = 0;

      // 단리 또는 복리에 따라 월 납입금액 계산
      if (interestType === "단리") {
        monthlyDeposit =
          goal / (((time * (time + 1)) / (2 * 12)) * rate + time);
      } else if (interestType === "복리") {
        monthlyDeposit = goal / (Math.pow(1 + rate / 12, time) - 1);
      }

      // 원금 설정 (목표금액 그대로 표시)
      setDepositAmount(goal.toFixed(0));

      preTax = ((monthlyDeposit * (time * (time + 1))) / (2 * 12)) * rate;

      // 이자 과세에 따른 세금률
      if (taxType === "일반") {
        taxRate = 0.154; // 15.4%
      } else if (taxType === "세금 우대") {
        taxRate = 0.095; // 9.5%
      } else if (taxType === "비과세") {
        taxRate = 0; // 0%
      }

      const tax = preTax * taxRate; // 세금 계산
      const afterTax = preTax - tax; // 세후 이자

      // 실수령액 계산
      const totalReturn = monthlyDeposit * time + afterTax; // 월 납입금 * 기간 + 세후 이자
      const afterTaxYield = (afterTax / (monthlyDeposit * time)) * 100; // 세후 수익률

      setPreTaxInterest(preTax.toFixed(0)); // 세전 이자 업데이트
      setTaxAmount(tax.toFixed(0)); // 세금 업데이트
      setAfterTaxReturn(totalReturn.toFixed(0)); // 실수령액 업데이트
      setAfterTaxYield(afterTaxYield.toFixed(2)); // 세후 수익률 업데이트
      setIsLoading(false); // 로딩 종료
    }, 2000); // 2초 후에 계산 완료
  };

  // Tab switching
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="interest-calculator">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <Char />
            <h3>잠시만 기다려주세요..</h3>
            <BeatLoader color="#10B9BE" />
          </div>
        </div>
      )}
      <div className="interest-calculator-header">
        <div style={{ marginLeft: "150px" }}>
          <h1>금융계산기</h1>
          <p>원하는 금액을 편리하게 계산해볼 수 있습니다.</p>
        </div>
      </div>
      <div className="interest-calculator-tabs">
        <div
          className={`tab1 ${activeTab === "예금" ? "active" : ""}`}
          onClick={() => handleTabChange("예금")}
        >
          예금
        </div>
        <div
          className={`tab1 ${activeTab === "적금" ? "active" : ""}`}
          onClick={() => handleTabChange("적금")}
        >
          적금
        </div>
      </div>

      {/* 예금 계산 */}
      {activeTab === "예금" && (
        <div className="interest-calculator-form">
          <div className="form-row">
            <label style={{ flexBasis: "150px" }}>예치금액</label>
            <div className="input-container-cal">
              <input
                type="text"
                value={depositAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} // 천 단위 콤마 추가
                onChange={handleDepositChange}
                className="input-field"
              />
              <span className="input-unit">만원</span>
            </div>
          </div>

          <div className="form-row">
            <label style={{ flexBasis: "150px" }}>예치기간</label>
            <div className="input-container-cal">
              <input
                type="text"
                value={period}
                onChange={handlePeriodChange}
                className="input-field"
              />
              <span className="input-unit">개월</span>
            </div>
          </div>

          <div className="form-row">
            <label style={{ flexBasis: "150px" }}>연이자율</label>
            <div className="input-container-cal">
              <input
                type="text"
                value={interestRate}
                onChange={handleInterestChange}
                className="input-field"
              />
              <span className="input-unit">(%)</span>
            </div>
          </div>

          <div className="form-row">
            <label>이자구분</label>
            <div className="radio-group">
              <label
                className={`radio-option ${
                  interestType === "단리" ? "selected" : ""
                }`}
              >
                <input
                  type="radio"
                  value="단리"
                  checked={interestType === "단리"}
                  onChange={handleInterestTypeChange}
                />
                단리
              </label>
              <label
                className={`radio-option ${
                  interestType === "복리" ? "selected" : ""
                }`}
              >
                <input
                  type="radio"
                  value="복리"
                  checked={interestType === "복리"}
                  onChange={handleInterestTypeChange}
                />
                복리
              </label>
            </div>
          </div>

          <div className="form-row">
            <label>이자과세</label>
            <div className="radio-group">
              <label
                className={`radio-option ${
                  taxType === "일반" ? "selected" : ""
                }`}
              >
                <input
                  type="radio"
                  value="일반"
                  checked={taxType === "일반"}
                  onChange={handleTaxTypeChange}
                />
                일반과세
              </label>
              <label
                className={`radio-option ${
                  taxType === "세금 우대" ? "selected" : ""
                }`}
              >
                <input
                  type="radio"
                  value="세금 우대"
                  checked={taxType === "세금 우대"}
                  onChange={handleTaxTypeChange}
                />
                세금 우대
              </label>
              <label
                className={`radio-option ${
                  taxType === "비과세" ? "selected" : ""
                }`}
              >
                <input
                  type="radio"
                  value="비과세"
                  checked={taxType === "비과세"}
                  onChange={handleTaxTypeChange}
                />
                비과세
              </label>
            </div>
          </div>

          <button
            className="calculate-button"
            onClick={calculateDepositInterest}
          >
            결과확인
          </button>
        </div>
      )}

      {/* 적금 계산 */}
      {activeTab === "적금" && (
        <>
          <div className="saving-tabs">
            <div
              className={`saving-tab ${
                subTab === "월납입액기준" ? "active" : ""
              }`}
              onClick={() => handleSubTabChange("월납입액기준")}
            >
              월납입액기준
            </div>
            <div
              className={`saving-tab ${
                subTab === "목표금액기준" ? "active" : ""
              }`}
              onClick={() => handleSubTabChange("목표금액기준")}
            >
              목표금액기준
            </div>
          </div>

          {subTab === "월납입액기준" && (
            <div className="interest-calculator-form">
              <div className="form-row">
                <label style={{ flexBasis: "150px" }}>월 납입액</label>
                <div className="input-container-cal">
                  <input
                    type="text"
                    value={monthlyAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} // 천 단위 콤마 추가
                    onChange={handleMonthlyAmountChange}
                    className="input-field"
                  />
                  <span className="input-unit">만원</span>
                </div>
              </div>

              <div className="form-row">
                <label style={{ flexBasis: "150px" }}>납입개월</label>
                <div className="input-container-cal">
                  <input
                    type="text"
                    value={savingPeriod}
                    onChange={handleSavingPeriodChange}
                    className="input-field"
                  />
                  <span className="input-unit">개월</span>
                </div>
              </div>

              <div className="form-row">
                <label style={{ flexBasis: "150px" }}>금리</label>
                <div className="input-container-cal">
                  <input
                    type="text"
                    value={savingInterestRate}
                    onChange={handleSavingInterestChange}
                    className="input-field"
                  />
                  <span className="input-unit">%</span>
                </div>
              </div>

              <div className="form-row">
                <label>이자구분</label>
                <div className="radio-group">
                  <label
                    className={`radio-option ${
                      interestType === "단리" ? "selected" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      value="단리"
                      checked={interestType === "단리"}
                      onChange={handleInterestTypeChange}
                    />
                    단리
                  </label>
                  <label
                    className={`radio-option ${
                      interestType === "복리" ? "selected" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      value="복리"
                      checked={interestType === "복리"}
                      onChange={handleInterestTypeChange}
                    />
                    복리
                  </label>
                </div>
              </div>

              <div className="form-row">
                <label>이자과세</label>
                <div className="radio-group">
                  <label
                    className={`radio-option ${
                      taxType === "일반" ? "selected" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      value="일반"
                      checked={taxType === "일반"}
                      onChange={handleTaxTypeChange}
                    />
                    일반과세
                  </label>
                  <label
                    className={`radio-option ${
                      taxType === "세금 우대" ? "selected" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      value="세금 우대"
                      checked={taxType === "세금 우대"}
                      onChange={handleTaxTypeChange}
                    />
                    세금 우대
                  </label>
                  <label
                    className={`radio-option ${
                      taxType === "비과세" ? "selected" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      value="비과세"
                      checked={taxType === "비과세"}
                      onChange={handleTaxTypeChange}
                    />
                    비과세
                  </label>
                </div>
              </div>

              <button
                className="calculate-button"
                onClick={calculateSavingInterest}
              >
                결과확인
              </button>
            </div>
          )}

          {subTab === "목표금액기준" && (
            <div className="interest-calculator-form">
              <div className="form-row">
                <label style={{ flexBasis: "150px" }}>총 목표금액</label>
                <div className="input-container-cal">
                  <input
                    type="text"
                    value={goalAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} // 천 단위 콤마 추가
                    onChange={(e) => setGoalAmount(e.target.value)}
                    className="input-field"
                  />
                  <span className="input-unit">만원</span>
                </div>
              </div>

              <div className="form-row">
                <label style={{ flexBasis: "150px" }}>납입개월</label>
                <div className="input-container-cal">
                  <input
                    type="text"
                    value={savingPeriod}
                    onChange={handleSavingPeriodChange}
                    className="input-field"
                  />
                  <span className="input-unit">개월</span>
                </div>
              </div>

              <div className="form-row">
                <label style={{ flexBasis: "150px" }}>금리</label>
                <div className="input-container-cal">
                  <input
                    type="text"
                    value={savingInterestRate}
                    onChange={handleSavingInterestChange}
                    className="input-field"
                  />
                  <span className="input-unit">%</span>
                </div>
              </div>

              <div className="form-row">
                <label>이자구분</label>
                <div className="radio-group">
                  <label
                    className={`radio-option ${
                      interestType === "단리" ? "selected" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      value="단리"
                      checked={interestType === "단리"}
                      onChange={handleInterestTypeChange}
                    />
                    단리
                  </label>
                  <label
                    className={`radio-option ${
                      interestType === "복리" ? "selected" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      value="복리"
                      checked={interestType === "복리"}
                      onChange={handleInterestTypeChange}
                    />
                    복리
                  </label>
                </div>
              </div>

              <div className="form-row">
                <label>이자과세</label>
                <div className="radio-group">
                  <label
                    className={`radio-option ${
                      taxType === "일반" ? "selected" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      value="일반"
                      checked={taxType === "일반"}
                      onChange={handleTaxTypeChange}
                    />
                    일반과세
                  </label>
                  <label
                    className={`radio-option ${
                      taxType === "세금 우대" ? "selected" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      value="세금 우대"
                      checked={taxType === "세금 우대"}
                      onChange={handleTaxTypeChange}
                    />
                    세금 우대
                  </label>
                  <label
                    className={`radio-option ${
                      taxType === "비과세" ? "selected" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      value="비과세"
                      checked={taxType === "비과세"}
                      onChange={handleTaxTypeChange}
                    />
                    비과세
                  </label>
                </div>
              </div>

              <button
                className="calculate-button"
                onClick={calculateGoalAmountInterest}
              >
                결과확인
              </button>
            </div>
          )}
        </>
      )}

      {/* 계산 결과 */}
      <div className="calculation-result">
        <h2>계산결과</h2>
        <div
          className="result-table"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <div className="result-row">
              <span className="interest-cal-result-text">원금</span>
              <span>{parseInt(depositAmount).toLocaleString()} 원</span>
            </div>
            <div className="result-row">
              <span className="interest-cal-result-text">세전이자</span>
              <span>{parseInt(preTaxInterest).toLocaleString()} 원</span>
            </div>
            <div className="result-row">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className="interest-cal-result-text">이자과세</span>
                <span
                  style={{ fontSize: "13px", color: "#AAA", marginTop: "5px" }}
                >
                  일반과세:15.4% / 세금우대:9.5% / 비과세:0%
                </span>
              </div>
              <span>{parseInt(taxAmount).toLocaleString()} 원</span>
            </div>
          </div>
          <div>
            <div className="result-row">
              <span className="interest-cal-result-text">세후수익률</span>
              <span>{afterTaxYield} %</span>
            </div>
            <div className="result-row total">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>실수령액</span>
                <span
                  style={{
                    fontSize: "13px",
                    color: "#AAA",
                    marginTop: "5px",
                    fontWeight: "normal",
                  }}
                >
                  원금 + 세전이자 - 이자과세
                </span>
              </div>
              <span>{parseInt(afterTaxReturn).toLocaleString()} 원</span>
            </div>
          </div>
        </div>
        <p className="disclaimer">
          * 상기 계산 결과는 실제 계약 상황에 따라 달라질 수 있습니다.
        </p>
      </div>
    </div>
  );
}

export default InterestCal;
