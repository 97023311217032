import React, { useState, useEffect, useRef } from "react";
import "../../../assets/css/InquiryResult.css";
import { ReactComponent as ArrowUp } from "../../../assets/svg/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../../assets/svg/arrow-down.svg";
import { useNavigate } from "react-router-dom";
import useTaxStore from "../../../store/taxStore";
import useAuthStore from "../../../store/useStore";
import useYearEndStore from "../../../store/yearEndStore";
import { ReactComponent as ShareIcon } from "../../../assets/svg/share-icon.svg";
import { ReactComponent as Kakao } from "../../../assets/svg/kakao.svg";
import { ReactComponent as Link } from "../../../assets/svg/link.svg";
import { ReactComponent as Pdf } from "../../../assets/svg/pdf.svg";
import axios from "axios";
import Modal from "react-modal";
import html2pdf from "html2pdf.js";
import { ToastContainer, toast } from "react-custom-alert";

const DeductionResultYearEnd = () => {
  const navigate = useNavigate();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const resultRef = useRef();
  const [pdfDownloadUrl, setPdfDownloadUrl] = useState("");

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const totalIncome = useTaxStore((state) => state.totalIncome);
  const wageIncomeAmount = useTaxStore((state) => state.wageIncomeAmount);
  const taxPaidValue = useTaxStore((state) => state.taxPaidValue);
  const cardDeductionAmount = useYearEndStore(
    (state) => state.cardDeductionAmount
  );
  const traditionalMarketDeduction = useYearEndStore(
    (state) => state.traditionalMarketDeduction
  );
  const busTrafficDeduction = useYearEndStore(
    (state) => state.busTrafficDeduction
  );
  const cultureDeduction = useYearEndStore((state) => state.cultureDeduction);
  const wageIncomeDeduction = useTaxStore((state) => state.wageIncomeDeduction);
  const personDeduction = useYearEndStore((state) => state.personDeduction);
  const houseDeductionAmount = useYearEndStore(
    (state) => state.houseDeductionAmount
  );
  const user = useAuthStore((state) => state.user);
  const monthlyHouseDeductionAmount = useYearEndStore(
    (state) => state.monthlyHouseDeductionAmount
  );
  const businessDeduction = useYearEndStore((state) => state.businessDeduction);
  const insuranceDeduction = useYearEndStore(
    (state) => state.insuranceDeduction
  );

  const previousYearDeduction = useYearEndStore(
    (state) => state.previousYearDeduction
  );

  const estimatedTaxAmount = useTaxStore((state) => state.estimatedTaxAmount);

  const [joinAccount, setJoinAccount] = useState(0);

  //산출세액 계산
  const calculateTax = (taxableIncome) => {
    if (taxableIncome <= 14000000) {
      return taxableIncome * 0.06;
    } else if (taxableIncome <= 50000000) {
      return 840000 + (taxableIncome - 14000000) * 0.15;
    } else if (taxableIncome <= 88000000) {
      return 6240000 + (taxableIncome - 50000000) * 0.24;
    } else if (taxableIncome <= 150000000) {
      return 15360000 + (taxableIncome - 88000000) * 0.35;
    } else if (taxableIncome <= 300000000) {
      return 37060000 + (taxableIncome - 150000000) * 0.38;
    } else if (taxableIncome <= 500000000) {
      return 94060000 + (taxableIncome - 300000000) * 0.4;
    } else if (taxableIncome <= 1000000000) {
      return 174060000 + (taxableIncome - 500000000) * 0.42;
    } else {
      return 384060000 + (taxableIncome - 1000000000) * 0.45;
    }
  };
  const taxableIncome = useTaxStore((state) => state.taxableIncome); //과세표준

  const taxAmount = useTaxStore((state) => state.taxAmount); //산출세액

  const [isOpen, setIsOpen] = useState(true);

  const insuranceHouseMontly =
    Number(insuranceDeduction) +
    Number(houseDeductionAmount) +
    Number(monthlyHouseDeductionAmount);

  const standardTaxDeduction = insuranceHouseMontly >= 130000 ? 0 : 130000;

  const toggleDetails = () => {
    setIsOpen(!isOpen);
  };

  const ToHome = () => {
    navigate("/");
  };

  const ToBack = () => {
    navigate(-1);
  };

  const copyLink = () => {
    const link = window.location.href;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("링크가 복사되었습니다.");
      })
      .catch(() => {
        toast.error("링크 복사에 실패했습니다.");
      });
  };

  const saveAsPdf = () => {
    const element = resultRef.current;

    const shareIcon = document.querySelector(".hideShareIcon");
    shareIcon.classList.add("hide-for-pdf");

    const today = new Date();
    const formattedDate = today.toISOString().slice(0, 10); // YYYY-MM-DD
    const userName = user?.name;

    const options = {
      margin: 0.5,
      filename: `${userName}_${formattedDate}_연말정산결과.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .from(element)
      .set(options)
      .save()
      .outputPdf("blob") // Blob 형태로 PDF 출력
      .then((pdfBlob) => {
        const pdfUrl = URL.createObjectURL(pdfBlob); // Blob URL 생성
        setPdfDownloadUrl(pdfUrl); // PDF 다운로드 URL 상태에 저장

        // PDF 저장 후 공유 아이콘 표시
        const shareIcon = document.querySelector(".hideShareIcon");
        shareIcon.classList.remove("hide-for-pdf");

        window.open(pdfUrl, "_blank");
      });
    closeConfirmationModal();
  };

  const userId = user?.id;
  useEffect(() => {
    const fetchJoinHistory = async () => {
      try {
        const response = await axios.post("/api/join-history/fund", {
          id: userId, // 사용자 ID를 바인딩
        });
        const totalJoinAccount = response.data.reduce(
          (acc, item) => acc + item.joinAccount,
          0
        );
        setJoinAccount(totalJoinAccount);
      } catch (error) {
        console.error("Error fetching join history:", error);
      }
    };

    fetchJoinHistory();
  }, []);

  const pensionDeductionLimit =
    joinAccount > 9000000
      ? 0
      : joinAccount > 6000000
      ? 600000
      : Math.min(joinAccount, 600000);

  const totalTaxDeduction =
    Number(businessDeduction) +
    Number(monthlyHouseDeductionAmount) +
    Number(standardTaxDeduction) +
    Number(wageIncomeDeduction) +
    Number(insuranceDeduction) +
    Number(pensionDeductionLimit);

  const totalDeductions =
    Number(cardDeductionAmount) +
    Number(personDeduction) +
    Number(traditionalMarketDeduction) +
    Number(busTrafficDeduction) +
    Number(cultureDeduction) +
    Number(houseDeductionAmount);

  const [detailItems] = useState([
    { label: "근로소득 공제", amount: wageIncomeAmount.toLocaleString() },
    { label: "종합소득 공제", amount: totalDeductions.toLocaleString() },
  ]);
  const openModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const shareToKakao = () => {
    closeConfirmationModal();
    if (!window.Kakao || !window.Kakao.isInitialized()) {
      console.error("Kakao SDK가 초기화되지 않았습니다.");
      return;
    }
    const userName = user?.name;

    const pdfUrl = pdfDownloadUrl;
    console.log(pdfDownloadUrl);
    window.Kakao.Link.sendDefault({
      objectType: "feed",
      content: {
        title: "연말정산 결과 공유",
        description: `지금까지의 ${userName}님의 연말정산 결과를 공유합니다!`,
        imageUrl: "http://hanatax.site/images/hanatax_kakao.png",
        imageWidth: 1200,
        imageHeight: 630,
        link: {
          mobileWebUrl: pdfUrl,
          webUrl: pdfUrl,
        },
      },
      itemContent: {
        profileText: `${user.name}`,
      },
      buttons: [
        {
          title: "자세히 보기",
          link: {
            mobileWebUrl: pdfUrl,
            webUrl: pdfUrl,
          },
        },
      ],
    });
  };

  useEffect(() => {
    const loadKakaoSDK = () => {
      if (!window.Kakao) {
        const script = document.createElement("script");
        script.src = "https://developers.kakao.com/sdk/js/kakao.min.js"; // Kakao SDK 추가
        script.onload = () => {
          if (!window.Kakao.isInitialized()) {
            window.Kakao.init(process.env.REACT_APP_KAKAO_APP_KEY);
            console.log("Kakao SDK 초기화 완료");
          }
        };
        document.head.appendChild(script);
      } else {
        if (!window.Kakao.isInitialized()) {
          window.Kakao.init(process.env.REACT_APP_KAKAO_APP_KEY);
          console.log("Kakao SDK 초기화 완료");
        }
      }
    };

    loadKakaoSDK();
  }, []);

  return (
    <div className="deduction-result-container">
      <div className="total-refund" ref={resultRef}>
        <div className="share-header">
          <h1>연말정산 결과확인</h1>
          <ShareIcon onClick={openModal} className="hideShareIcon" />
        </div>
        <Modal
          isOpen={isConfirmationModalOpen}
          onRequestClose={closeConfirmationModal}
          contentLabel="신청 완료 모달"
          className="modal"
          overlayClassName="overlay-modal"
        >
          <div>
            <span>공유하기</span>
            <div className="modal-body">
              <div className="share-option" onClick={saveAsPdf}>
                <div className="icon-wrapper">
                  <Pdf />
                </div>
                <span style={{ fontSize: "13px", fontWeight: "normal" }}>
                  pdf저장
                </span>
              </div>
              <div
                className="share-option"
                onClick={
                  window.Kakao && window.Kakao.isInitialized()
                    ? shareToKakao
                    : null
                }
                disabled={!window.Kakao || !window.Kakao.isInitialized()}
              >
                <div className="icon-wrapper">
                  <Kakao />
                </div>
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: "normal",
                  }}
                >
                  카카오톡
                </span>
              </div>

              <div className="share-option" onClick={copyLink}>
                <div className="icon-wrapper">
                  <Link />
                </div>
                <span style={{ fontSize: "13px", fontWeight: "normal" }}>
                  링크복사
                </span>
              </div>
            </div>
            <button
              className="modal-confirm-btn"
              onClick={closeConfirmationModal}
            >
              닫기
            </button>
          </div>
        </Modal>
        <div className="refund-detail-item">
          <span>나의 총급여</span>
          <span>{Number(totalIncome).toLocaleString()}원</span>
        </div>
        <div onClick={toggleDetails} className="refund-detail-item">
          나의 공제내역{" "}
          {isOpen ? (
            <ArrowUp className="toggle-arrow" />
          ) : (
            <ArrowDown className="toggle-arrow" />
          )}{" "}
        </div>

        {isOpen && (
          <div className="details-container">
            {detailItems.map((item, index) => (
              <div className="refund-detail-item" key={index}>
                <span>{item.label}</span>
                <span>{item.amount}원</span>
              </div>
            ))}
            <div className="deduction-detail-container">
              <div className="deduction-detail-item">
                <span>카드 공제</span>
                <span>{Number(cardDeductionAmount).toLocaleString()}원</span>
              </div>
              <div className="deduction-detail-item">
                <span>가족 공제</span>
                <span>{Number(personDeduction).toLocaleString()}원</span>
              </div>
              <div className="deduction-detail-item">
                <span>전통시장</span>
                <span>
                  {Number(traditionalMarketDeduction).toLocaleString()}원
                </span>
              </div>
              <div className="deduction-detail-item">
                <span>대중교통</span>
                <span>{Number(busTrafficDeduction).toLocaleString()}원</span>
              </div>
              <div className="deduction-detail-item">
                <span>도서･공연･영화･신문･박물관･미술관</span>
                <span>{Number(cultureDeduction).toLocaleString()}원</span>
              </div>
              <div className="deduction-detail-item">
                <span>전년도 카드 증가분</span>
                <span>{Number(previousYearDeduction).toLocaleString()}원</span>
              </div>

              <div className="deduction-detail-item">
                <span>주택 공제</span>
                <span>{Number(houseDeductionAmount).toLocaleString()}원</span>
              </div>
              <div className="deduction-detail-item">
                <span>기타 공제</span>
                <span>0원</span>
              </div>
            </div>
            <div className="refund-detail-item">
              <span>종합소득 과세표준</span>
              <span>
                {(taxableIncome - totalDeductions).toLocaleString()}원
              </span>
            </div>
            <div className="refund-detail-item">
              <span>산출세액</span>
              <span>
                {Math.floor(
                  calculateTax(taxableIncome - totalDeductions)
                ).toLocaleString()}
                원
              </span>
            </div>
            <div className="refund-detail-item">
              <span>세금 공제</span>
              <span>{totalTaxDeduction.toLocaleString()}원</span>
            </div>
            <div className="deduction-detail-container">
              <div className="deduction-detail-item">
                <span>근로소득</span>
                <span>{Number(wageIncomeDeduction).toLocaleString()}원</span>
              </div>
              <div className="deduction-detail-item">
                <span>중소기업 감면</span>
                <span>{Number(businessDeduction).toLocaleString()}원</span>
              </div>
              <div className="deduction-detail-item">
                <span>월세 공제</span>
                <span>
                  {Number(monthlyHouseDeductionAmount).toLocaleString()}원
                </span>
              </div>
              <div className="deduction-detail-item">
                <span>연금 공제</span>
                <span>{pensionDeductionLimit.toLocaleString()}원</span>
              </div>
              <div className="deduction-detail-item">
                <span>보험료</span>
                <span>{Number(insuranceDeduction).toLocaleString()}원</span>
              </div>

              <div className="deduction-detail-item">
                <span>표준세액공제</span>
                <span>{standardTaxDeduction.toLocaleString()}원</span>
              </div>
              <div className="deduction-detail-item">
                <span>기타</span>
                <span>0원</span>
              </div>
            </div>
            <div className="refund-detail-item">
              <span>먼저 낸 세금</span>
              <span>{Number(taxPaidValue).toLocaleString()}원</span>
            </div>
          </div>
        )}
        <div className="refund-detail-item">
          {Math.floor(
            calculateTax(taxableIncome - totalDeductions) -
              totalDeductions -
              totalTaxDeduction -
              taxPaidValue
          ) > 0 ? (
            <span>추가 납부해야 할 세금</span>
          ) : (
            <span>환급받는 금액</span>
          )}
          <span>
            {Math.floor(
              calculateTax(taxableIncome - totalDeductions) -
                totalDeductions -
                totalTaxDeduction -
                taxPaidValue
            ) < 0
              ? Math.abs(
                  Math.floor(
                    calculateTax(taxableIncome - totalDeductions) -
                      totalDeductions -
                      totalTaxDeduction -
                      taxPaidValue
                  )
                ).toLocaleString()
              : Math.floor(
                  calculateTax(taxableIncome - totalDeductions) -
                    totalDeductions -
                    totalTaxDeduction -
                    taxPaidValue
                ).toLocaleString()}{" "}
            원
          </span>
        </div>

        <div className="solutionBefore">
          <span style={{ marginRight: "15px" }}>솔루션 전 비교 </span>

          <div className="solutionAfter">
            {Math.floor(
              estimatedTaxAmount -
                (calculateTax(taxableIncome - totalDeductions) -
                  totalDeductions -
                  totalTaxDeduction -
                  taxPaidValue)
            ) > 0 ? (
              <span style={{ color: "red" }}> ▲ </span>
            ) : (
              <span style={{ color: "blue" }}> ▼ </span>
            )}
            <span>
              {Math.abs(
                Math.floor(
                  estimatedTaxAmount -
                    (calculateTax(taxableIncome - totalDeductions) -
                      totalDeductions -
                      totalTaxDeduction -
                      taxPaidValue)
                )
              ).toLocaleString()}{" "}
              원
            </span>
          </div>
        </div>

        <ToastContainer floatingTime={5000} />
      </div>
      <div className="buttons">
        <button className="btn-close" onClick={ToBack}>
          닫기
        </button>
        <button className="btn-confirm" onClick={ToHome}>
          확인
        </button>
      </div>
    </div>
  );
};

export default DeductionResultYearEnd;
