import React from "react";
import "../../assets/css/Allof.css"; // 스타일 시트 경로
import { ReactComponent as Char } from "../../assets/svg/연말정산/연말정산_모든것.svg";

const YearEndSection = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleLinkClick = (event, sectionId) => {
    event.preventDefault(); // 기본 링크 동작 방지
    scrollToSection(sectionId);
  };

  return (
    <div className="yearend-section">
      <h2>"연말정산이 뭐에요?" 연말정산 쉽게 이해하기</h2>

      <div className="yearend-content">
        <Char className="allofChar" />
      </div>
      <div className="content">
        <h3>연말정산, 꼭 알아야 할 이유</h3>
        <p>
          연말정산은 내가 한 해 동안 벌어들인 소득과 지출을 비교하여 최종적으로
          납부해야 할 세금을 계산하는 과정입니다. 매달 월급에서 미리 공제된
          세금이 적정하게 처리되었는지를 확인하는 절차라고 할 수 있습니다. 예를
          들어, 친구들과 여행을 간다고 생각해보세요. 친구가 총무 역할을 맡아
          돈을 걷고, 여행 중에 그 돈을 사용합니다. 만약 돈이 남았다면, 그 남은
          돈을 각자의 기여도에 따라 다시 나누어주는 것이죠. 즉, 숙소를 잘 예약한
          친구에게는 더 많이 주는 식입니다.<br></br>
        </p>
        <p>
          예를 들어 친구들과 놀러간다고 합시다. 친구 한 명이 총무 역할을 맡아서
          돈을 걷어서 여행 동안 신나게 썼을거 아니예요. 근데 넉넉하게 돈을
          걷었더니 이 돈이 남았네요? 그럼 이 돈을 다시 돌려주는겁니다. <br></br>
          대신 다 똑같이 주는 것이 아니라 기여도에 따라 다르게 돌려주는 것이죠.
          예를들어 여행할 때 식당찾는데 기여했어. 숙소 좋은데 잘 예약해 줬어
          이런 친구한테 조금 더 많은 돈을 돌려주는 겁니다.
        </p>
        <div className="toc-container">
          <h3>목차</h3>
          <p>
            <a href="#" onClick={(e) => handleLinkClick(e, "section1")}>
              1. 연말정산이 무엇일까?
            </a>
          </p>
          <p>
            <a href="#" onClick={(e) => handleLinkClick(e, "section2")}>
              2. 연말정산의 필요성
            </a>
          </p>
          <p>
            <a href="#" onClick={(e) => handleLinkClick(e, "section3")}>
              3. 연말정산 대상자 살펴보기
            </a>
          </p>
        </div>
        <h3 id="section1">1) 연말정산이란 무엇인가요?</h3>
        <p>자, 조금더 쉽게 설명을 해볼게요.</p>
        <p>
          {" "}
          우리가 취업하여 월급을 받게 되면 매달 급여에서 국세청의 '간이세액표'에
          따라 소득세가 미리 공제됩니다. 이렇게 공제된 세금은 정부에 보관되며,
          월급명세서에는 지급액과 함께 소득세, 주민세, 각종 보험료가 공제된 후
          최종 실수령액이 표시됩니다. 하지만 세금을 공제할 때 개인의 상황은
          고려되지 않기 때문에, 연말에는 정확한 세액을 다시 계산하여 더 떼어간
          경우에는 돌려주고, 덜 떼어간 경우에는 더 내는 과정을 거치게 됩니다.
          즉, <b>내 급여에서 임의로 공제한 세금이 적정한지 확인하는 과정</b>이
          바로 '연말정산'입니다 <br></br>
        </p>
        <p></p>
        <p>
          내 급여에서 임의로 세금을 뗴어갔으니 정확하게 세금을 얼마를 내는 게
          맞는 것인지 따져보는겁니다.
          <br></br>
        </p>
        <br></br>
        <h3 id="section2">2) 연말정산, 꼭 해야 할까?</h3>
        <p>
          연말정산은 필수는 아닙니다. 하지만 증빙서류를 제출하지 않으면
          결과적으로 결정세액이 늘어나게 됩니다. 서류를 제출하지 않으면 정부가
          판단한 소득 수준에 따라 세액이 결정되므로, '기본적인 공제 항목'만
          적용받게 되어 추가 세금을 납부해야 할 가능성이 높아집니다. 같은
          연봉이라도 꼼꼼하게 준비한 경우와 그렇지 않은 경우의 환급액은 큰
          차이가 납니다.
        </p>

        <p>
          같은 연봉이라도 꼼꼼히 준비해서 제출하면 환급받는 사람이 있고,
          토해내기도 합니다. 토해내는 금액도 차이가 나고요. 때문에 연말정산 전에
          똑똑한 사람들은 공제받을 수 있는 팁을 적극 활용하는 것을 보셨을거예요.
          그래서 연말정산을 '13월의 월급' 이라는 표현을 쓰기도 하니까요.
        </p>
        <br></br>
        <h3 id="section3">3) 나는 연말정산 대상자일까?</h3>
        <p>
          연말정산은 직장 근로를 통해 얻은 소득에 대한 세금 정산이기 때문에,
          일반적으로 직장인만 해당됩니다. 아르바이트생이나 프리랜서는
          제외되지만, 1년 이상 근무한 계약직은 예외적으로 연말정산을 진행해야
          합니다.
        </p>
        <p>
          일반적으로 프리랜서분들은 수입에서 3.3%의 세금을 공제하고 지급받게
          됩니다 (3% 소득세, 0.3% 지방소득세) 그런데 수입에서 3.3% 세금이
          차감되었다고 세금신고가 끝난 게 아닌데요. 3.3% 세금은 임시로 부과된
          세금일 뿐이고, 세금신고를 확정하는 절차가 필요합니다. 바로 그 절차가
          종합소득세 신고입니다. 5월 종합소득세 신고를 통해 납부할 세금이 있다면
          5월에 납부하고, 환급받을 세금이 있다면 5월에 신고해 6월 중에 환급받는
          것이죠.
        </p>
      </div>
    </div>
  );
};

export default YearEndSection;
