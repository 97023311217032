import React, { useState, useEffect } from "react";
import "../../assets/css/Solution.css";
import { ReactComponent as CardIcon } from "../../assets/svg/연말정산/credit-card.svg";
import { ReactComponent as ArrowUp } from "../../assets/svg/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrow-down.svg";
import { ReactComponent as Rate } from "../../assets/svg/money-rate.svg";
import useCardStore from "../../store/cardStore";
import useTaxStore from "../../store/taxStore";
import useYearEndStore from "../../store/yearEndStore";
import { useNavigate } from "react-router-dom";

const CardDetails = () => {
  const [isOpen, setIsOpen] = useState(false);
  const previousYearDeduction = useYearEndStore(
    (state) => state.previousYearDeduction
  );
  const setPreviousYearDeduction = useYearEndStore(
    (state) => state.setPreviousYearDeduction
  );

  const naviate = useNavigate();

  const creditCards = useCardStore((state) => state.creditCards); // 신용카드 목록 가져오기
  const debitCards = useCardStore((state) => state.debitCards); // 체크카드 목록 가져오기
  const directCards = useCardStore((state) => state.directCards); // 직불카드 목록 가져오기
  const prepaidCards = useCardStore((state) => state.prepaidCards); // 선불카드 목록 가져오기
  const totalIncome = useTaxStore((state) => state.totalIncome); // 총 급여

  const setCardDeductionAmount = useYearEndStore(
    (state) => state.setCardDeductionAmount
  );

  const setTraditionalMarketDeduction = useYearEndStore(
    (state) => state.setTraditionalMarketDeduction
  );
  const setBusTrafficDeduction = useYearEndStore(
    (state) => state.setBusTrafficDeduction
  );
  const setCultureDeduction = useYearEndStore(
    (state) => state.setCultureDeduction
  );
  console.log(creditCards);

  const isCardDateIn2024 = (cardDate) => {
    const date = new Date(cardDate);
    return date.getFullYear() === 2024;
  };

  const isCardDateIn2023 = (cardDate) => {
    const date = new Date(cardDate);
    return date.getFullYear() === 2023;
  };
  // 공제 계산 시 제외할 카테고리 (공제 계산 시 제외, 사용액에서도 제외)
  const excludedCategoriesForDeduction = [50, 51, 59, 60, 61, 62];

  // 전체 카드 사용액에는 더하지만, 신용카드 사용액이나 체크카드 사용액에서는 빼야함.
  const excludedCategoriesForUsage = [52, 53, 54, 55, 56, 57];

  const totalCreditCardAmount = creditCards
    .filter(
      (card) =>
        !excludedCategoriesForUsage.includes(Number(card.category)) &&
        isCardDateIn2024(card.cardDate)
    )
    .reduce((acc, card) => acc + card.amount, 0);

  //체크카드, 직불카드, 카드 사용액
  const totalDebitDirectPrepaidCardAmount = [
    ...debitCards.filter(
      (card) =>
        !excludedCategoriesForUsage.includes(Number(card.category)) &&
        isCardDateIn2024(card.cardDate)
    ),
    ...directCards.filter(
      (card) =>
        !excludedCategoriesForUsage.includes(Number(card.category)) &&
        isCardDateIn2024(card.cardDate)
    ),
    ...prepaidCards.filter(
      (card) =>
        !excludedCategoriesForUsage.includes(Number(card.category)) &&
        isCardDateIn2024(card.cardDate)
    ),
  ].reduce((acc, card) => acc + card.amount, 0);

  const totalCreditCardAmount2023 = creditCards
    .filter(
      (card) =>
        !excludedCategoriesForUsage.includes(Number(card.category)) &&
        isCardDateIn2023(card.cardDate)
    )
    .reduce((acc, card) => acc + card.amount, 0);

  //체크카드, 직불카드, 카드 사용액
  const totalDebitDirectPrepaidCardAmount2023 = [
    ...debitCards.filter(
      (card) =>
        !excludedCategoriesForUsage.includes(Number(card.category)) &&
        isCardDateIn2023(card.cardDate)
    ),
    ...directCards.filter(
      (card) =>
        !excludedCategoriesForUsage.includes(Number(card.category)) &&
        isCardDateIn2023(card.cardDate)
    ),
    ...prepaidCards.filter(
      (card) =>
        !excludedCategoriesForUsage.includes(Number(card.category)) &&
        isCardDateIn2023(card.cardDate)
    ),
  ].reduce((acc, card) => acc + card.amount, 0);

  const total2024 = totalCreditCardAmount + totalDebitDirectPrepaidCardAmount;

  const total2023 =
    totalCreditCardAmount2023 + totalDebitDirectPrepaidCardAmount2023;
  const deduction2423 = Math.min(total2024 - total2023 * 1.05, 1000000);

  // 공제 계산을 위한 필터링
  const filteredCreditCardsForDeduction = creditCards.filter(
    (card) =>
      !excludedCategoriesForUsage.includes(Number(card.category)) &&
      isCardDateIn2024(card.cardDate)
  );

  const filteredDebitDirectPrepaidCardsForDeduction = [
    ...debitCards.filter(
      (card) =>
        !excludedCategoriesForUsage.includes(Number(card.category)) &&
        !excludedCategoriesForDeduction.includes(Number(card.category)) &&
        isCardDateIn2024(card.cardDate)
    ),
    ...directCards.filter(
      (card) =>
        !excludedCategoriesForUsage.includes(Number(card.category)) &&
        !excludedCategoriesForDeduction.includes(Number(card.category)) &&
        isCardDateIn2024(card.cardDate)
    ),
    ...prepaidCards.filter(
      (card) =>
        !excludedCategoriesForUsage.includes(Number(card.category)) &&
        !excludedCategoriesForDeduction.includes(Number(card.category)) &&
        isCardDateIn2024(card.cardDate)
    ),
  ];

  const totalFilteredCreditCardAmount = filteredCreditCardsForDeduction.reduce(
    (acc, card) => acc + card.amount,
    0
  );

  const totalFilteredDebitDirectPrepaidCardAmount =
    filteredDebitDirectPrepaidCardsForDeduction.reduce(
      (acc, card) => acc + card.amount,
      0
    );

  // 전통시장, 대중교통, 문화비 사용액 필터링 및 합산
  const traditionalMarketAmount = [
    ...creditCards,
    ...debitCards,
    ...directCards,
    ...prepaidCards,
  ]
    .filter((card) => Number(card.category) === 52)
    .reduce((acc, card) => acc + card.amount, 0);

  const busTrafficAmount = [
    ...creditCards,
    ...debitCards,
    ...directCards,
    ...prepaidCards,
  ]
    .filter((card) => Number(card.category) === 53)
    .reduce((acc, card) => acc + card.amount, 0);

  const cultureAmount = [
    ...creditCards,
    ...debitCards,
    ...directCards,
    ...prepaidCards,
  ]
    .filter(
      (card) =>
        Number(card.category) === 54 ||
        Number(card.category) === 55 ||
        Number(card.category) === 56 ||
        Number(card.category) === 57
    )
    .reduce((acc, card) => acc + card.amount, 0);

  const creditCardSpendThreshold = totalIncome * 0.25; // 총 급여의 25%

  // 신용카드 사용액이 총 급여의 25%를 초과할 때만 공제 가능
  const creditDeductionAmount =
    totalFilteredCreditCardAmount > creditCardSpendThreshold
      ? (totalFilteredCreditCardAmount - creditCardSpendThreshold) * 0.15
      : 0;

  const debitDeductionAmount =
    totalFilteredCreditCardAmount > creditCardSpendThreshold
      ? totalFilteredDebitDirectPrepaidCardAmount * 0.3
      : 0;

  // 전통시장, 대중교통, 문화비 각각의 공제 계산
  const traditionalMarketDeduction =
    totalFilteredCreditCardAmount > creditCardSpendThreshold
      ? traditionalMarketAmount * 0.3
      : 0;
  const busTrafficDeduction =
    totalFilteredCreditCardAmount > creditCardSpendThreshold
      ? busTrafficAmount * 0.4
      : 0;
  const cultureDeduction =
    totalFilteredCreditCardAmount > creditCardSpendThreshold
      ? cultureAmount * 0.3
      : 0;

  // 특수 공제 항목 총합 계산 (최대 한도 적용)
  const totalSpecialDeduction = Math.floor(
    Math.min(
      traditionalMarketDeduction + busTrafficDeduction + cultureDeduction,
      totalIncome > 70000000 ? 2000000 : 3000000 // 특수 항목 공제 한도
    )
  );

  // 전체 공제액 계산 (최대 한도 적용)
  const totalDeduction = Math.floor(
    Math.min(
      creditDeductionAmount + debitDeductionAmount + totalSpecialDeduction,
      totalIncome > 70000000 ? 2500000 : 3000000 // 총 공제 한도
    )
  );

  // 추가된 변수
  const creditCardAmount = totalFilteredCreditCardAmount; // 신용카드 사용액
  const debitCardAmount = totalFilteredDebitDirectPrepaidCardAmount; // 체크카드 사용액
  const allcreditCardSpendThreshold = totalIncome * 0.25; // 총 급여의 25%

  // 신용카드 공제 계산
  const allcreditDeductionAmount =
    creditCardAmount > creditCardSpendThreshold
      ? (creditCardAmount - creditCardSpendThreshold) * 0.15
      : 0;

  // 체크카드 공제 계산 (신용카드 공제 조건 충족 시)
  const alldebitDeductionAmount =
    creditCardAmount > creditCardSpendThreshold ? debitCardAmount * 0.3 : 0;

  // 총 세제 혜택 계산
  const totalTaxBenefit = creditDeductionAmount + debitDeductionAmount;

  // 최적의 카드 사용 비율 안내
  const recommendedCreditCardUsage =
    creditCardSpendThreshold > creditCardAmount
      ? creditCardSpendThreshold - creditCardAmount
      : 0;

  useEffect(() => {
    setCardDeductionAmount(Math.floor(totalDeduction));
    setTraditionalMarketDeduction(traditionalMarketDeduction);
    setBusTrafficDeduction(busTrafficDeduction);
    setCultureDeduction(cultureDeduction);
    setPreviousYearDeduction(deduction2423);
  }, [
    totalDeduction,
    traditionalMarketDeduction,
    busTrafficDeduction,
    cultureDeduction,
    setCardDeductionAmount,
    setTraditionalMarketDeduction,
    setBusTrafficDeduction,
    setCultureDeduction,
    setPreviousYearDeduction,
  ]);

  const amountToSpendForDeduction =
    creditCardSpendThreshold > totalFilteredCreditCardAmount
      ? creditCardSpendThreshold - totalFilteredCreditCardAmount
      : 0;

  const maxCardDeductionLimit = totalIncome > 70000000 ? 4500000 : 6000000;

  const totalOtherAmount =
    busTrafficAmount + traditionalMarketAmount + cultureAmount;

  const items = [
    {
      // 신용카드와 체크카드/직불카드를 합친 후 하나의 바에 표시
      percentage: 100,
      description: "카드 기본 공제",
      color: "#90B6FF", // 바의 기본 색상 (신용카드)
      amount:
        totalFilteredCreditCardAmount +
        totalFilteredDebitDirectPrepaidCardAmount,
      unit: 300,
      subBars: [
        {
          percentage:
            totalFilteredCreditCardAmount /
            (totalFilteredCreditCardAmount +
              totalFilteredDebitDirectPrepaidCardAmount),
          description: "신용카드",
          color: "#90B6FF", // 신용카드 색상
          amount: totalFilteredCreditCardAmount,
        },
        {
          percentage:
            (totalFilteredDebitDirectPrepaidCardAmount /
              (totalFilteredCreditCardAmount +
                totalFilteredDebitDirectPrepaidCardAmount)) *
            100,
          description: "체",
          color: "#FEA6FA", // 체크카드/직불카드 색상
          amount: totalFilteredDebitDirectPrepaidCardAmount,
        },
      ],
    },
    {
      percentage: 100, // 전체 바는 300만원 기준 그대로 사용
      description: "카드 추가 공제",
      color: "#E0C7FF", // 대중교통 색상 (기본 색상으로 사용됨)
      amount: totalSpecialDeduction, // 공제 받은 총액
      unit: 300, // 단위 만원
      subBars: [
        {
          percentage: (busTrafficDeduction / totalSpecialDeduction) * 100, // 실제 비율 숫자 그대로 사용
          description: "교통",
          color: "#E0C7FF", // 대중교통 색상
          amount: busTrafficDeduction,
        },
        {
          percentage:
            (traditionalMarketDeduction / totalSpecialDeduction) * 100,
          description: "전통시장",
          color: "#8D9CD0", // 전통시장 색상
          amount: traditionalMarketDeduction,
        },
        ...(totalIncome <= 70000000
          ? [
              {
                percentage: (cultureDeduction / totalSpecialDeduction) * 100,
                description: "문화비",
                color: "#FF9797", // 문화비 색상
                amount: cultureDeduction,
              },
            ]
          : []),
      ],
    },
    {
      percentage: deduction2423 > 0 ? 100 : 0, // 그대로 비율 사용
      description: "전년도 소비증가분",
      color: "#FFDC97",
      amount: deduction2423,
      unit: 100,
    },
  ];

  const toggleDetails = () => {
    setIsOpen(!isOpen);
  };

  const recommendedCreditUsageRatio =
    creditCardAmount + debitCardAmount < creditCardSpendThreshold
      ? 100 // 신용카드로만 소비해야 함
      : (creditCardSpendThreshold / (creditCardAmount + debitCardAmount)) * 100; // 신용카드 25%, 나머지는 체크카드

  const recommendedDebitUsageRatio = 100 - recommendedCreditUsageRatio; // 체크카드는 신용카드 사용 비율을 제외한 나머지 비율

  // 추천되는 소비 비율을 기반으로 절세 효과 계산
  const maxTaxBenefit = Math.floor(
    Math.min(
      totalTaxBenefit, // 총 세제 혜택
      totalIncome > 70000000 ? 2500000 : 3000000 // 총 공제 한도
    )
  );
  console.log("amount" + amountToSpendForDeduction); // 값이 정확히 0인지 확인

  return (
    <div className="card-container">
      <div
        className="card-header"
        onClick={toggleDetails}
        style={{ padding: "10px" }}
      >
        <CardIcon />
        <span>카드 공제 추가하기</span>
        {isOpen ? (
          <ArrowUp className="toggle-arrow" />
        ) : (
          <ArrowDown className="toggle-arrow" />
        )}
      </div>
      <div className={`card-animation-container ${isOpen ? "open" : ""}`}>
        <div className="card-body">
          <div className="card-box">
            {totalCreditCardAmount > creditCardSpendThreshold ? (
              <p>
                카드 소득공제 가능 금액은 <br />
                {totalDeduction.toLocaleString()} 원 받을 수 있습니다.
              </p>
            ) : (
              <p>
                소비금액이 적어 <br />
                카드 소득공제를 받을 수 없어요.
              </p>
            )}
            {/* <div className="text" style={{ padding: "0 10px" }}>
              {amountToSpendForDeduction > 0 && (
                <span>
                  앞으로 신용카드로 추가{" "}
                  {amountToSpendForDeduction.toLocaleString()}원
                  <br />더 써야 공제를 받을 수 있어요.
                </span>
              )}
            </div> */}
            <div className="discount-details">
              {items.map((item, index) => (
                <div className="discount-item" key={index}>
                  <div className="progress-all-bar">
                    {item.subBars ? (
                      <div
                        className="progress-bar-container"
                        style={{ display: "flex" }}
                      >
                        {item.subBars.map((subBar, subIndex) => (
                          <div
                            key={subIndex}
                            className="progress-bar"
                            style={{
                              width: `${
                                subBar.amount > 0
                                  ? (subBar.amount / 3000000) * 100
                                  : 0
                              }%`,
                              backgroundColor: subBar.color,
                              display: "inline-block",
                              textAlign: "center",
                              padding: "5px 0",
                              color: "#fff",
                            }}
                          >
                            <span style={{ fontSize: "12px" }}>
                              {subBar.description}
                            </span>
                            <br />
                            <span style={{ fontSize: "12px", color: "black" }}>
                              {(subBar.amount > 0
                                ? (subBar.amount / 3404550) * 100
                                : 0
                              ).toFixed(1)}
                              %
                            </span>
                          </div>
                        ))}
                        <div
                          className="progress-bar1"
                          style={{
                            width: `${Math.max(
                              0,
                              100 -
                                item.subBars.reduce(
                                  (acc, subBar) =>
                                    acc + (subBar.amount / 3000000) * 100,
                                  0
                                )
                            )}%`,
                          }}
                        ></div>
                      </div>
                    ) : (
                      <div
                        className="progress-bar1"
                        style={{
                          width: `${item.percentage}%`,
                          backgroundColor: item.color,
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <div className="percentage1" style={{ color: "black" }}>
                          {item.percentage.toFixed(1)}%
                        </div>
                      </div>
                    )}
                    <div className="amount-unit">
                      <div
                        className="card-amount"
                        style={{
                          marginLeft: `${Math.max(0, item.percentage - 5)}%`,
                        }}
                      ></div>
                      <div className="unit">
                        {item.unit.toLocaleString()}(만원)
                      </div>
                    </div>
                  </div>
                  <div className="description-unit">
                    <div
                      className="description-color"
                      style={{
                        backgroundColor: item.color,
                        width: "14px",
                        height: "10px",
                        marginRight: "10px",
                      }}
                    ></div>
                    <div className="card-description">{item.description}</div>
                  </div>
                </div>
              ))}
            </div>
            <div class="account-make-btn-box">
              <button
                class="card-detail-report"
                onClick={() => naviate("/myCardReport")}
              >
                소비 자세히 보기
              </button>
            </div>
          </div>
          <div
            className="card-box"
            style={{
              marginTop: "30px",
              backgroundColor: "#fff",
              padding: "35px 20px",
              borderRadius: "10px",
            }}
          >
            <div className="progress-bar-container1">
              <div className="progress-bar1">
                {/* 신용카드 사용 비율 */}
                <div
                  className="credit-progress"
                  style={{
                    width: `${Math.min(
                      (recommendedCreditUsageRatio / 100) * 100, // 신용카드 추천 비율
                      100
                    )}%`,
                    backgroundColor: "#ffcc29", // 신용카드 색상
                  }}
                ></div>
                {/* 체크카드 사용 비율 */}
                <div
                  className="debit-progress"
                  style={{
                    width: `${Math.min(
                      (recommendedDebitUsageRatio / 100) * 100, // 체크카드 추천 비율
                      100
                    )}%`,
                    backgroundColor: "#90B6FF", // 체크카드 색상
                  }}
                ></div>
              </div>
              <span className="badge" style={{ color: "#fff" }}>
                <Rate style={{ marginRight: "2px" }} /> 추천 황금비율
              </span>
            </div>

            <div className="card-amounts">
              <div className="debit-card">
                <span>체크카드</span>
                <span className="amount1" style={{ fontSize: "16px" }}>
                  {totalFilteredDebitDirectPrepaidCardAmount.toLocaleString()}원
                </span>
              </div>
              <div className="credit-card">
                <span>신용카드</span>
                <span className="amount1" style={{ fontSize: "16px" }}>
                  {totalFilteredCreditCardAmount.toLocaleString()}원
                </span>
              </div>
            </div>

            {/* 소비 전략 안내 부분 */}
            <div className="input-summary1">
              {totalFilteredCreditCardAmount +
                totalFilteredDebitDirectPrepaidCardAmount <
              creditCardSpendThreshold ? (
                <div>
                  {/* 신용카드와 체크카드의 사용액이 총급여의 25%를 넘지 않는 경우 */}
                  <div className="input-row1">
                    <span>
                      앞으로 신용카드와 체크카드의 사용 비율을{" "}
                      <strong>0:10</strong>으로 설정하세요. <br />
                    </span>
                  </div>
                  <div className="input-row1">
                    <span style={{ fontSize: "14px" }}>
                      체크카드를 주로 사용해 추가로{" "}
                      {amountToSpendForDeduction.toLocaleString()}원 더 공제를
                      받을 수 있습니다.
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  {/* 신용카드 사용액이 총급여의 25%를 넘는 경우 */}
                  <div className="input-row1">
                    <span>
                      신용카드 사용액이 충분하므로 앞으로 신용카드와 체크카드의
                      비율을 <strong>25:75</strong>로 유지하세요.
                    </span>
                  </div>
                  <div className="input-row1">
                    <span style={{ fontSize: "16px" }}>
                      <strong>{maxTaxBenefit.toLocaleString()}원</strong> 절세
                      효과를 얻을 수 있습니다.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDetails;
