import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../../assets/css/Product.css";
import Modal from "react-modal";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

function JoinProduct2() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [allChecked, setAllChecked] = useState(false);
  const [confirmationChecked, setConfirmationChecked] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [modalIsOpen3, setModalIsOpen3] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const pdfUrl = "/pdf/isa_약관.pdf";
  const pdfUrl2 = "/pdf/isa_계약서.pdf";

  const [checks, setChecks] = useState({
    box1: {
      term: false,
      term2: false,
      term3: false,
      term4: false,
      term5: false,
    }, // 첫 번째 박스 체크 상태
    box2: { term: false, term2: false, term3: false }, // 두 번째 박스 체크 상태
    box3: { term: false, term2: false }, // 세 번째 박스 체크 상태
    box4: { term: false, term2: false, term3: false }, // 네 번째 박스 체크 상태
    box5: { term: false, confirmation: false }, // 다섯 번째 박스 체크 상태
  });

  const openModal1 = () => {
    setModalIsOpen(true);
  };

  const closeModal1 = () => {
    setModalIsOpen(false);
  };

  const openModal2 = () => {
    setModalIsOpen2(true);
  };

  const closeModal2 = () => {
    setModalIsOpen2(false);
  };

  const openModal3 = () => {
    setModalIsOpen3(true);
  };

  const closeModal3 = () => {
    setModalIsOpen3(false);
  };

  const renderAgreementContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <p>
            투자대상 <br />
            <br />
            국내외 주식형 펀드, 채권형 펀드, 혼합형 펀드 및 현금성 자산(RP, MMF)
            등으로 운용 <br />※ 투자대상 및 비중은 모델포트폴리오별로 상이{" "}
            <br /> <br />
            주요 투자위험 <br />
            <br />
            최초 계약 체결시 제시된 모델포트폴리오에 포함된 자산군별 비중 및
            개별 금융상품은 시장 상황을 감안하여 해당 모델포트폴리오의 위험도를
            벗어나지 않는 범위 내에서 변경될 수 있습니다. <br />
            특정 종목, 섹터 및 금융상품의 비중이 높은 모델포트폴리오의 경우
            수익률의 변동성이 커질 수 있습니다. <br /> 해외자산에 투자하는
            모델포트폴리오의 경우 해당 국가의 통화가치 변동 및 지정학적 위험에
            따른 손실이 발생할 수 있습니다. <br />
            모델포트폴리오 위험도 산정방식(편입금융상품 위험도 가중평균)으로
            인해 투자자 성향에 비해 위험도가 높거나 낮은 금융상품이 편입될 수
            있습니다. <br />이 개인종합자산관리계좌(ISA)는 예금자보호법에 따라
            예금보호 대상으로 운용되는 금융상품에 한하여 1인당
            “5천만원까지”(운용되는 금융상품 판매회사별 보호상품 합산)
            보호됩니다. 시장상황에 따라 원금 전액손실 가능하며, 운용 결과에 따른
            이익 또는 손실은 투자자에게 귀속됩니다. <br />
            가입일 또는 연장일이 속한 과세기간의 직전 3개 과세기간 동안 1회 이상
            금융소득종합과세대상자인 경우 해당 계좌는 해지되며, 세제 혜택분은
            추징됩니다.
          </p>
        );
      case 2:
        return (
          <p>
            가입요건 <br />
            <br />
            거주자 중 19세 이상인 자 또는 근로소득이 있는 15세이상 ~ 19세 미만인
            자 <br />※ 가입일 또는 연장일이 속한 과세기간 직전 3개 과세기간 중
            1회 이상 금융소득종합과세 대상자인 경우 가입 불가 <br />
            <br />
            필요서류 <br />
            <br />
            일반형: 가입시 서류 필요 없음
            <br /> 서민형: 소득확인증명서(개인종합관리계좌 가입용) <br />
            농어민: 농·어업인확인서 + 소득확인증명서(개인종합자산관리계좌
            가입용) <br />※ 서민형 가입요건 - 총급여 5천만원 이하 근로소득자
            또는 종합소득 3천8백만원 이하 사업소득자 <br />※ 농어민은 온라인
            가입 불가
            <br />
            <br />
            세제혜택 <br />
            <br />
            일반형: 비과세 한도 200만원(초과분 9.9% 분리과세) <br />
            서민형·농어민: 비과세 한도 400만원(초과분 9.9% 분리과세)
            <br /> ※ 가입 후 부적격으로 판명될 경우 일반 일임계약과 동일하게
            과세하여 원천징수 되며, 연장 후 부적격으로 판명될 경우에는 계좌 내
            상품의 매도 완료시점 또는 기획재정부령이 정하는 기간(30일) 중 빠른
            날까지 과세특례 적용 의무가입기간 3년 납입한도 매년 2천만원
            범위내에서 납입이 가능하며, 전년도 미납입 금액에 대하여 이월하여
            납입 가능합니다. <br />
            단, 전체가입기간 동안 총 한도(1억원에서 재형저축 및
            소득공제장기펀드의 잔여한도를 차감)를 초과하여 납입 불가합니다.
          </p>
        );
      case 3:
        return <p>출금 및 해지 내용...</p>;
      case 4:
        return <p>금융소비자 권리 내용...</p>;
      default:
        return <p>약관 내용을 선택하세요.</p>;
    }
  };

  const renderAgreementContent2 = () => {
    if (activeTab === 1) {
      return (
        <div>
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js`}
          >
            <Viewer fileUrl={pdfUrl} />
          </Worker>
        </div>
      );
    } else if (activeTab === 2) {
      return (
        <div>
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js`}
          >
            <Viewer fileUrl={pdfUrl2} />
          </Worker>
        </div>
      );
    } else {
      return <p>약관 내용을 선택하세요.</p>;
    }
  };

  const renderAgreementContent3 = () => {
    return (
      <div>
        <p>비예금상품 설명서 확인</p>

        {/* Q1 */}
        <div>
          <p
            style={{
              backgroundColor: "rgba(75, 203, 156, 0.26)",
              padding: "10px",
            }}
          >
            Q. 본 상품은 예・적금과 다른 상품으로서 원금손실 위험이 있습니다.
            원금손실 발생 가능성에 대해 어떻게 생각하십니까?
          </p>
          <label>
            <input type="radio" name="q1" value="highRisk" />
            높은 수익률을 위해 감수해야 한다.
          </label>
          <br />
          <label>
            <input type="radio" name="q1" value="minorRisk" />
            경미한 수준이다.
          </label>
          <br />
          <label>
            <input type="radio" name="q1" value="noRisk" />
            거의 없다.
          </label>
        </div>

        {/* Q2 */}
        <div>
          <p>Q. 본 상품의 최대 원금손실 가능금액에 대해 어떻게 생각하시나요?</p>
          <label>
            <input type="radio" name="q2" value="fullLoss" />
            원금전액손실이 발생할 수 있다.
          </label>
          <br />
          <label>
            <input type="radio" name="q2" value="minorLoss" />
            경미한 수준이다.
          </label>
        </div>

        {/* Q3 */}
        <div>
          <p>
            Q. 원금손실 발생 가능성과 최대 원금손실 가능금액을 정확하게
            이해하였으며, 이러한 위험에도 불구하고 본 상품에 가입하시겠습니까?
          </p>
          <label>
            <input type="radio" name="q3" value="yes" />예
          </label>
          <br />
          <label>
            <input type="radio" name="q3" value="no" />
            아니요
          </label>
        </div>
      </div>
    );
  };

  const handleBox1Check = (event) => {
    const { checked } = event.target;

    // 체크박스 상태 업데이트
    setChecks((prevChecks) => ({
      ...prevChecks,
      box1: {
        term: checked,
        term2: checked,
        term3: checked,
        term4: checked,
        term5: checked,
      },
    }));

    // 모달 열기
    if (checked) {
      openModal1();
    }
  };

  // 두 번째 박스 전체 동의
  const handleBox2Check = (event) => {
    const { checked } = event.target;
    setChecks((prevChecks) => ({
      ...prevChecks,
      box2: {
        term: checked,
        term2: checked,
        term3: checked,
      },
    }));

    if (checked) {
      openModal2();
    }
  };

  // 세 번째 박스 전체 동의
  const handleBox3Check = (event) => {
    const { checked } = event.target;
    setChecks((prevChecks) => ({
      ...prevChecks,
      box3: {
        term: checked,
        term2: checked,
      },
    }));
    if (checked) {
      openModal3();
    }
  };

  // 네 번째 박스 전체 동의
  const handleBox4Check = (event) => {
    const { checked } = event.target;
    setChecks((prevChecks) => ({
      ...prevChecks,
      box4: {
        term: checked,
        term2: checked,
        term3: checked,
      },
    }));
  };

  // 다섯 번째 박스 전체 동의
  const handleBox5Check = (event) => {
    const { checked } = event.target;
    setChecks((prevChecks) => ({
      ...prevChecks,
      box5: {
        term: checked,
        confirmation: checked,
      },
    }));
  };

  useEffect(() => {
    // 모든 박스의 필수 체크박스들이 체크되었는지 확인
    const allBoxesChecked =
      checks.box1.term2 &&
      checks.box1.term3 &&
      checks.box1.term4 &&
      checks.box1.term5 &&
      checks.box2.term2 &&
      checks.box2.term3 &&
      checks.box3.term2 &&
      checks.box4.term2 &&
      checks.box5.term2 &&
      confirmationChecked;

    setAllChecked(allBoxesChecked);
  }, [checks, confirmationChecked]);

  const handleCheckboxChange = (box, name) => {
    setChecks((prevChecks) => ({
      ...prevChecks,
      [box]: { ...prevChecks[box], [name]: !prevChecks[box][name] },
    }));
  };

  const handleConfirmationChange = (event) => {
    setConfirmationChecked(event.target.checked);
  };

  return (
    <div className="signup-product-container">
      {/* 진행 상황 */}
      <div className="progress-steps">
        <button className="step">1</button>
        <button className="step active">2.약관동의</button>
        <button className="step">3</button>
        <button className="step">4</button>
        <button className="step">5</button>
      </div>

      {/* 첫 번째 박스 */}
      <div className="agreement-product-section">
        <p>금융상품의 중요사항 및 필수 확인사항</p>
        <div className="agreement-product-header">
          <label>
            <input
              type="checkbox"
              checked={checks.box1.term}
              onChange={handleBox1Check}
            />{" "}
            전체동의
          </label>
        </div>
        <ul className="agreement-product-list">
          <li>
            <div>
              <label className="join-isa-agreement-detail-box">
                <div>
                  <input
                    type="checkbox"
                    checked={checks.box1.term2}
                    onChange={() => handleCheckboxChange("box1", "term2")}
                  />{" "}
                  <span
                    style={{ color: "#099A96", fontFamily: "Pretendard-Bold" }}
                  >
                    [필수]
                  </span>{" "}
                  금융소비자보호법상 중요사항
                </div>
                <button
                  style={{
                    backgroundColor: "#fffcfc",
                    border: "1px solid #898989",
                    borderRadius: "5px",
                    color: "#898989",
                    padding: "5px",
                    fontFamily: "Pretendard-Regular",
                  }}
                >
                  자세히 보기
                </button>
              </label>
            </div>
          </li>
          <li>
            <label className="join-isa-agreement-detail-box">
              <div>
                <input
                  type="checkbox"
                  checked={checks.box1.term3}
                  onChange={() => handleCheckboxChange("box1", "term3")}
                />{" "}
                <span
                  style={{ color: "#099A96", fontFamily: "Pretendard-Bold" }}
                >
                  [필수]
                </span>{" "}
                가입요건 및 세제혜택 등
              </div>
              <button
                style={{
                  backgroundColor: "#fffcfc",
                  border: "1px solid #898989",
                  borderRadius: "5px",
                  color: "#898989",
                  padding: "5px",
                  fontFamily: "Pretendard-Regular",
                }}
              >
                자세히 보기
              </button>
            </label>
          </li>
          <li>
            <label className="join-isa-agreement-detail-box">
              <div>
                <input
                  type="checkbox"
                  checked={checks.box1.term4}
                  onChange={() => handleCheckboxChange("box1", "term4")}
                />{" "}
                <span
                  style={{ color: "#099A96", fontFamily: "Pretendard-Bold" }}
                >
                  [필수]
                </span>{" "}
                출금 및 해지
              </div>
              <button
                style={{
                  backgroundColor: "#fffcfc",
                  border: "1px solid #898989",
                  borderRadius: "5px",
                  color: "#898989",
                  padding: "5px",
                  fontFamily: "Pretendard-Regular",
                }}
              >
                자세히 보기
              </button>
            </label>
          </li>
          <li>
            <label className="join-isa-agreement-detail-box">
              <div>
                <input
                  type="checkbox"
                  checked={checks.box1.term5}
                  onChange={() => handleCheckboxChange("box1", "term5")}
                />{" "}
                <span
                  style={{ color: "#099A96", fontFamily: "Pretendard-Bold" }}
                >
                  [필수]
                </span>{" "}
                금융소비자 권리
              </div>
              <button
                style={{
                  backgroundColor: "#fffcfc",
                  border: "1px solid #898989",
                  borderRadius: "5px",
                  color: "#898989",
                  padding: "5px",
                  fontFamily: "Pretendard-Regular",
                }}
              >
                자세히 보기
              </button>
            </label>
          </li>
        </ul>
      </div>

      {/* 두 번째 박스 */}
      <div className="agreement-product-section">
        <p>약관, 계약권유문서 확인</p>
        <div className="agreement-product-header">
          <label>
            <input
              type="checkbox"
              checked={checks.box2.term}
              onChange={handleBox2Check}
            />{" "}
            전체동의
          </label>
        </div>
        <ul className="agreement-product-list">
          <li>
            <label className="join-isa-agreement-detail-box">
              <div>
                <input
                  type="checkbox"
                  checked={checks.box2.term2}
                  onChange={() => handleCheckboxChange("box2", "term2")}
                />{" "}
                <span
                  style={{ color: "#099A96", fontFamily: "Pretendard-Bold" }}
                >
                  [필수]
                </span>{" "}
                약관
              </div>
              <button
                style={{
                  backgroundColor: "#fffcfc",
                  border: "1px solid #898989",
                  borderRadius: "5px",
                  color: "#898989",
                  padding: "5px",
                  fontFamily: "Pretendard-Regular",
                }}
              >
                자세히 보기
              </button>
            </label>
          </li>
          <li>
            <label className="join-isa-agreement-detail-box">
              <div>
                <input
                  type="checkbox"
                  checked={checks.box2.term3}
                  onChange={() => handleCheckboxChange("box2", "term3")}
                />{" "}
                <span
                  style={{ color: "#099A96", fontFamily: "Pretendard-Bold" }}
                >
                  [필수]
                </span>{" "}
                계약권유문서
              </div>
              <button
                style={{
                  backgroundColor: "#fffcfc",
                  border: "1px solid #898989",
                  borderRadius: "5px",
                  color: "#898989",
                  padding: "5px",
                  fontFamily: "Pretendard-Regular",
                }}
              >
                자세히 보기
              </button>
            </label>
          </li>
        </ul>
      </div>

      {/* 세 번째 박스 */}
      <div className="agreement-product-section">
        <p>비예금상품 설명서</p>
        <div className="agreement-product-header">
          <label>
            <input
              type="checkbox"
              checked={checks.box3.term}
              onChange={handleBox3Check}
            />{" "}
            전체동의
          </label>
        </div>
        <ul className="agreement-product-list">
          <li>
            <label className="join-isa-agreement-detail-box">
              <div>
                <input
                  type="checkbox"
                  checked={checks.box3.term2}
                  onChange={() => handleCheckboxChange("box3", "term2")}
                />{" "}
                <span
                  style={{ color: "#099A96", fontFamily: "Pretendard-Bold" }}
                >
                  [필수]
                </span>{" "}
                비예금상품 설명서 확인
              </div>
              <button
                style={{
                  backgroundColor: "#fffcfc",
                  border: "1px solid #898989",
                  borderRadius: "5px",
                  color: "#898989",
                  padding: "5px",
                  fontFamily: "Pretendard-Regular",
                }}
              >
                자세히 보기
              </button>
            </label>
          </li>
        </ul>
      </div>

      {/* 네 번째 박스 */}
      <div className="agreement-product-section">
        <p>투자자 확인사항</p>
        <div className="agreement-product-header">
          <label>
            <input
              type="checkbox"
              checked={checks.box4.term}
              onChange={handleBox4Check}
            />{" "}
            전체동의
          </label>
        </div>
        <ul className="agreement-product-list">
          <li>
            <label className="join-isa-agreement-detail-box">
              <input
                type="checkbox"
                checked={checks.box4.term2}
                onChange={() => handleCheckboxChange("box4", "term2")}
              />{" "}
              본인은 약관, 상품설명서 및 계약권유문서를 전자문서에 의해
              제공받았으며 충분히 이해하였습니다.
            </label>
          </li>
          <li>
            <label>
              <input
                type="checkbox"
                checked={checks.box4.term3}
                onChange={() => handleCheckboxChange("box4", "term3")}
              />{" "}
              예금자보호 여부를 확인하였으며 원금 손실 가능성에 동의합니다.
            </label>
          </li>
        </ul>
      </div>

      {/* 다섯 번째 박스 */}
      <div className="agreement-product-section">
        <p>불법·탈법 차명거래 금지 설명 확인</p>
        <ul className="agreement-product-list">
          <li>
            <label>
              <input
                type="checkbox"
                checked={checks.box5.term2}
                onChange={() => handleCheckboxChange("box5", "term2")}
              />{" "}
              불법·탈법 차명거래 금지 설명을 확인하였습니다.
            </label>
          </li>
        </ul>
      </div>
      <div className="agreement-product-footer">
        <label>
          <input
            type="checkbox"
            name="confirmation"
            checked={confirmationChecked}
            onChange={handleConfirmationChange}
          />{" "}
          본인은 위 ISA상품의 약관 및 상품설명서를 제공받고 ISA상품의 중요한
          사항을 충분히 이해하며 본 상품에 가입함을 확인합니다.
        </label>
      </div>

      {/* 최종 버튼 */}
      <div className="action-buttons">
        <button className="prev-button" onClick={() => navigate(-1)}>
          이전
        </button>
        <button
          className={`next-button ${allChecked ? "" : "disabled"}`}
          onClick={() => allChecked && navigate("/isa/product/join3")}
          disabled={!allChecked}
        >
          다음
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal1}
        contentLabel="약관 보기 모달"
        className="modal-agree"
        overlayClassName="overlay-modal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <span
              style={{
                display: "flex",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              금융상품의 중요사항 및 필수 확인사항
            </span>
          </div>

          <div className="modal-agreement-box">
            <div
              className={`tab-agreement ${activeTab === 1 ? "selected1" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              금융소비자보호법상 중요사항
            </div>
            <div
              className={`tab-agreement ${activeTab === 2 ? "selected1" : ""}`}
              onClick={() => setActiveTab(2)}
            >
              가입요건 및 세제혜택 등
            </div>
            <div
              className={`tab-agreement ${activeTab === 3 ? "selected1" : ""}`}
              onClick={() => setActiveTab(3)}
            >
              출금 및 해지
            </div>
            <div
              className={`tab-agreement ${activeTab === 4 ? "selected1" : ""}`}
              onClick={() => setActiveTab(4)}
            >
              금융소비자 권리
            </div>
          </div>

          <div className="modal-body1">{renderAgreementContent()}</div>
          <div className="modal-footer">
            <button
              className="modal-confirm-btn1"
              onClick={closeModal1}
              style={{ marginTop: "20px" }}
            >
              동의
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpen2}
        onRequestClose={closeModal2}
        contentLabel="약관 보기 모달"
        className="modal-agree"
        overlayClassName="overlay-modal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <span
              style={{
                display: "flex",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              약관, 계약권유문서 확인
            </span>
          </div>

          <div className="modal-agreement-box">
            <div
              className={`tab-agreement ${activeTab === 1 ? "selected1" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              약관
            </div>
            <div
              className={`tab-agreement ${activeTab === 2 ? "selected1" : ""}`}
              onClick={() => setActiveTab(2)}
            >
              계약권유문서
            </div>
          </div>

          <div className="modal-body1">{renderAgreementContent2()}</div>
          <div className="modal-footer">
            <button
              className="modal-confirm-btn1"
              onClick={closeModal2}
              style={{ marginTop: "20px" }}
            >
              동의
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpen3}
        onRequestClose={closeModal3}
        contentLabel="약관 보기 모달"
        className="modal-agree"
        overlayClassName="overlay-modal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <span
              style={{
                display: "flex",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              비예금상품 설명서
            </span>
          </div>

          <div className="modal-agreement-box">
            <div
              className={`tab-agreement ${activeTab === 1 ? "selected1" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              비예금상품 설명서 확인
            </div>
          </div>

          <div className="modal-body1">{renderAgreementContent3()}</div>
          <div className="modal-footer">
            <button
              className="modal-confirm-btn1"
              onClick={closeModal3}
              style={{ marginTop: "20px" }}
            >
              동의
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default JoinProduct2;
