import useAuthStore from "../../store/useStore";

class ActionProvider {
  constructor(createChatbotMessage, setStateFunc, createClientMessage) {
    this.createChatbotMessage = createChatbotMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage;
  }

  // 텍스트 입력 처리
  async handleMessage(userMessage) {
    try {
      const user = useAuthStore.getState().user;
      const userId = user ? `?userId=${user.id}` : "";
      console.log(userId);

      // 작성 중 메시지 추가 (애니메이션 효과)
      const typingMessage = this.createChatbotMessage(
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              marginRight: "5px",
              borderRadius: "50%",
              backgroundColor: "#ccc",
              animation: "typing 1s infinite ease-in-out",
            }}
          ></span>
          작성 중...
        </div>
      );

      // 애니메이션 keyframes 직접 추가
      const styleSheet = document.styleSheets[0];
      const keyframes = `
        @keyframes typing {
          0% { transform: scale(1); }
          50% { transform: scale(1.5); }
          100% { transform: scale(1); }
        }
      `;
      styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

      this.setState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, typingMessage], // 작성 중 메시지 추가
      }));

      // Fetch 요청
      const response = await fetch(
        `/api/chat/completion${userId}`, // userId가 있으면 쿼리 파라미터로 추가
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            model: "gpt-3.5-turbo", // 원하는 모델 선택
            messages: [
              {
                role: "user",
                content: userMessage, // 사용자의 메시지
              },
            ],
          }),
        }
      );

      if (!response.ok) {
        throw new Error("서버 응답 오류");
      }

      const data = await response.json();
      console.log(data);
      const improvementResponse = data.improvementResponse[0]; // 첫 번째 개선 응답
      const improvementPoint = improvementResponse.improvementPoint;

      // 받아온 응답 메시지로 "작성 중" 메시지 교체
      const botMessage = this.createChatbotMessage(improvementPoint);

      this.setState((prevState) => ({
        ...prevState,
        messages: [
          ...prevState.messages.slice(0, -1), // 작성 중 메시지 제거
          botMessage, // 응답 메시지 추가
        ],
      }));
    } catch (error) {
      console.error("메시지 처리 중 오류:", error);
    }
  }

  // 이미지 업로드 처리
  async handleImageUpload(imageFile) {
    try {
      const formData = new FormData();
      formData.append("file", imageFile);

      // 작성 중 메시지 추가 (이미지 처리 중)
      const typingMessage = this.createChatbotMessage(
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              marginRight: "5px",
              borderRadius: "50%",
              backgroundColor: "#ccc",
              animation: "typing 1s infinite ease-in-out",
            }}
          ></span>
          이미지 처리 중...
        </div>
      );

      this.setState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, typingMessage], // 작성 중 메시지 추가
      }));

      const styleSheet = document.styleSheets[0];
      const keyframes = `
        @keyframes typing {
          0% { transform: scale(1); }
          50% { transform: scale(1.5); }
          100% { transform: scale(1); }
        }
      `;
      styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

      // 백엔드로 POST 요청 보내기 (이미지 업로드 처리)
      const response = await fetch("/api/chat/upload-image", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("서버 응답 오류");
      }

      const data = await response.json();
      const botMessage = this.createChatbotMessage(data.answer); // 받아온 응답 메시지

      this.setState((prevState) => ({
        ...prevState,
        messages: [
          ...prevState.messages.slice(0, -1), // 작성 중 메시지 제거
          botMessage, // 응답 메시지 추가
        ],
      }));
    } catch (error) {
      console.error("이미지 처리 중 오류:", error);
    }
  }

  async leaveChatRoom() {
    try {
      const user = useAuthStore.getState().user;
      const userId = user ? user.id : "";
      const response = await fetch(`/api/chat/completion?userId=${userId}`, {
        method: "DELETE",
      });

      if (response.ok) {
        console.log("Chat history deleted successfully.");
      } else {
        console.error("Failed to delete chat history.");
      }
    } catch (error) {
      console.error("Error while deleting chat history:", error);
    }
  }
}

export default ActionProvider;
