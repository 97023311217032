import React, { useState, useEffect } from "react";
import "../../assets/css/MyPage.css";
import { ReactComponent as Char1 } from "../../assets/svg/하나얼굴_캐릭터.svg";
import { ReactComponent as ArrowRight } from "../../assets/svg/arrow-right-black.svg";
import { ReactComponent as ArrowLeft } from "../../assets/svg/arrow-left-black.svg";
import { ReactComponent as Cal } from "../../assets/svg/달력.svg";
import useStore from "../../store/useStore";
import useTokenStore from "../../store/tokenStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const MyCardReport = () => {
  const [isAssetsVisible, setIsAssetsVisible] = useState(false);
  const { user, setUserInfo, lastLoginTime } = useStore((state) => ({
    user: state.user,
    setUserInfo: state.setUserInfo,
    lastLoginTime: state.lastLoginTime,
  }));
  const { token } = useTokenStore();
  const navigate = useNavigate();
  const [profileName, setProfileName] = useState("");
  const [linkedAssets, setLinkedAssets] = useState("");
  const [ci, setCi] = useState("");
  const [chartSeries, setChartSeries] = useState([]);
  const [total2024, setTotal2024] = useState(0);
  const [total2023, setTotal2023] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(9);
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [weeklyData, setWeeklyData] = useState([]);
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(1);
  const selectedWeekData = weeklyData[selectedWeekIndex] || { categories: {} };
  const pieChartData = Object.entries(selectedWeekData.categories).map(
    ([name, value]) => ({
      name,
      value,
    })
  );

  const [cardHistory, setCardHistory] = useState([]);
  const [keywords, setKeywords] = useState([]);

  const COLORS = ["#3EB7A4", "#23ADDE", "#6171B4", "#8C7AB6", "#BB88B9"];
  const totalAmount = pieChartData.reduce((acc, entry) => acc + entry.value, 0);

  const handleWeekClick = (weekIndex) => {
    setSelectedWeekIndex(weekIndex);
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const categoryMap = {
    50: "공과금",
    51: "통신비",
    52: "전통시장",
    53: "교통비",
    54: "문화생활",
    55: "문화생활",
    56: "문화생활",
    57: "문화생활",
    58: "교육비",
    59: "면세점",
    60: "여행",
    61: "리스비용",
    62: "세금",
    63: "식비",
  };

  const spendingRanges = {
    하위20퍼: 94 * 12 * 10000, // 하위 20% 연간 소비
    구간2: 122 * 12 * 10000, // 2구간 연간 소비
    구간3: 150 * 12 * 10000, // 3구간 연간 소비
    구간4: 172 * 12 * 10000, // 4구간 연간 소비
    상위20퍼: 285 * 12 * 10000, // 상위 20% 연간 소비
  };

  // 사용자의 총 소비 금액이 어느 구간에 속하는지 판단하는 함수
  const calculatePercentile = (total) => {
    if (total <= spendingRanges.하위20퍼) {
      return "하위 20%";
    } else if (total <= spendingRanges.구간2) {
      return "2구간 (20% ~ 40%)";
    } else if (total <= spendingRanges.구간3) {
      return "3구간 (40% ~ 60%)";
    } else if (total <= spendingRanges.구간4) {
      return "4구간 (60% ~ 80%)";
    } else {
      return "상위 20%";
    }
  };

  const percentile = calculatePercentile(total2024);

  const calculateCategoryTotals = (cardHistory) => {
    const categoryTotals = {};

    cardHistory.forEach((item) => {
      const categoryLabel = categoryMap[item.category] || "기타";
      if (!categoryTotals[categoryLabel]) {
        categoryTotals[categoryLabel] = 0;
      }
      categoryTotals[categoryLabel] += item.amount;
    });

    return Object.entries(categoryTotals).map(([name, value]) => ({
      name,
      value,
    }));
  };

  const calculateWeeklyData = (cardHistory, month) => {
    const weeklyTotals = Array(5).fill(0);
    const weeklyCategoryData = Array(5)
      .fill(null)
      .map(() => ({}));

    cardHistory.forEach((item) => {
      const date = new Date(item.cardDate);
      if (date.getMonth() === month) {
        const day = date.getDate();
        let weekIndex = -1;

        if (day >= 1 && day <= 7) weekIndex = 0;
        else if (day >= 8 && day <= 14) weekIndex = 1;
        else if (day >= 15 && day <= 21) weekIndex = 2;
        else if (day >= 22 && day <= 28) weekIndex = 3;
        else if (day >= 29) weekIndex = 4;

        if (weekIndex !== -1) {
          weeklyTotals[weekIndex] += item.amount;

          const categoryLabel = categoryMap[item.category] || "기타";
          if (!weeklyCategoryData[weekIndex][categoryLabel]) {
            weeklyCategoryData[weekIndex][categoryLabel] = 0;
          }
          weeklyCategoryData[weekIndex][categoryLabel] += item.amount;
        }
      }
    });

    if (month === 9) {
      weeklyTotals.splice(2);
      weeklyCategoryData.splice(2);
    }

    const weeklyData = weeklyTotals
      .map((amount, index) => {
        if (amount > 0) {
          return {
            week: `${index + 1}주차`,
            dateRange: getWeekRange(index, month),
            amount,
            categories: weeklyCategoryData[index],
            comparison:
              index > 0 ? getComparison(amount, weeklyTotals[index - 1]) : "",
            increase: index > 0 ? amount > weeklyTotals[index - 1] : false,
          };
        }
        return null;
      })
      .filter(Boolean)
      .reverse();

    return weeklyData;
  };

  const getComparison = (currentAmount, previousAmount) => {
    const difference = currentAmount - previousAmount;
    if (difference > 0) {
      return `지난주보다 ${difference.toLocaleString()}원 더 소비했어요!`;
    } else if (difference < 0) {
      return `지난주보다 ${Math.abs(
        difference
      ).toLocaleString()}원 덜 소비했어요!`;
    } else {
      return "소비가 지난주와 동일해요!";
    }
  };

  const getWeekRange = (weekIndex, month) => {
    const weeks = [
      { start: 1, end: 7 },
      { start: 8, end: 14 },
      { start: 15, end: 21 },
      { start: 22, end: 28 },
      { start: 29, end: 31 },
    ];
    const { start, end } = weeks[weekIndex];
    return `${month + 1}월 ${start}일~${end}일`;
  };

  const getInvestmentType = (profileCode) => {
    switch (profileCode) {
      case 18:
        return "안정형";
      case 19:
        return "안정추구형";
      case 20:
        return "위험중립형";
      case 21:
        return "적극투자형";
      case 22:
        return "공격투자형";
      default:
        return "";
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  };

  const handleToggle = () => {
    setIsAssetsVisible(!isAssetsVisible);
  };

  const handleMonthChange = (direction) => {
    const newMonth = selectedMonth + direction;
    if (newMonth >= 0 && newMonth < 12) {
      setSelectedMonth(newMonth);
    }
  };

  useEffect(() => {
    const userId = user.id;

    axios
      .post("/api/user/risk-profile", { id: userId })
      .then((response) => {
        const profileCode = response.data.profileName;
        setProfileName(getInvestmentType(profileCode));
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setProfileName("");
        } else {
          console.error("risk-profile API 호출 오류", error);
        }
      });

    axios
      .post("/api/mydata/linked-assets", { id: userId })
      .then((response) => {
        const { linkedAssets, ci } = response.data;
        setLinkedAssets(linkedAssets);
        setCi(ci);

        const assetCodesArray = linkedAssets.split(",").map(Number);

        axios
          .post(
            "/api/mydata/enroll",
            {
              userId: userId,
              ci: ci,
              assetCodes: assetCodesArray,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((enrollResponse) => {
            setCardHistory(response.data);
            const cardHistory = enrollResponse.data;

            const cardHistory2023 = cardHistory.filter(
              (item) => new Date(item.cardDate).getFullYear() === 2023
            );
            const cardHistory2024 = cardHistory.filter(
              (item) => new Date(item.cardDate).getFullYear() === 2024
            );

            const calculateMonthlyTotals = (historyData) => {
              const monthlyTotals = Array(12).fill(0);
              historyData.forEach((item) => {
                const month = new Date(item.cardDate).getMonth();
                monthlyTotals[month] += item.amount;
              });
              return monthlyTotals;
            };

            const monthlyTotals2023 = calculateMonthlyTotals(cardHistory2023);
            const monthlyTotals2024 = calculateMonthlyTotals(cardHistory2024);

            const total2024Sum = monthlyTotals2024.reduce(
              (sum, amount) => sum + amount,
              0
            );
            setTotal2024(total2024Sum);

            const total2023Sum = monthlyTotals2023.reduce(
              (sum, amount) => sum + amount,
              0
            );
            setTotal2023(total2023Sum);

            setChartSeries([
              {
                name: "2023",
                data: monthlyTotals2023,
              },
              {
                name: "2024",
                data: monthlyTotals2024,
              },
            ]);

            setMonthlyTotal(monthlyTotals2024[selectedMonth]);
            setWeeklyData(calculateWeeklyData(cardHistory2024, selectedMonth));

            const topKeywords = calculateTopKeywords(enrollResponse.data);
            setKeywords(topKeywords);
            console.log(topKeywords);
          })
          .catch((error) => {
            console.error("Enroll API 호출 오류:", error);
          });
      })
      .catch((error) => {
        console.error("linked-assets API 호출 오류:", error);
      });
  }, [user.id, token, selectedMonth]);

  useEffect(() => {
    if (cardHistory.length > 0) {
      const topKeywords = calculateTopKeywords(cardHistory);
      console.log(topKeywords);
      setKeywords(topKeywords);
    }
  }, [cardHistory]);

  const calculateTopKeywords = (cardHistory) => {
    const categoryTotals = {};

    // 카테고리별로 사용 금액을 누적
    cardHistory.forEach((item) => {
      const categoryLabel = categoryMap[item.category] || "기타";

      // 금액이 숫자인지 확인하고, 아닌 경우 0으로 처리
      const amount =
        typeof item.amount === "number" && !isNaN(item.amount)
          ? item.amount
          : 0;

      if (!categoryTotals[categoryLabel]) {
        categoryTotals[categoryLabel] = 0;
      }
      categoryTotals[categoryLabel] += amount;
    });

    // 총 소비액 계산
    const totalAmount = Object.values(categoryTotals).reduce(
      (acc, value) => acc + value,
      0
    );

    // 총 소비액이 0인 경우 처리
    if (totalAmount === 0) {
      return Object.entries(categoryTotals).map(([category]) => ({
        category,
        percentage: 0,
      }));
    }

    // 비율 계산 및 키워드 형식으로 변환
    const topKeywords = Object.entries(categoryTotals)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 5) // 상위 5개 카테고리 선택
      .map(([category, amount]) => {
        const percentage = ((amount / totalAmount) * 100).toFixed(0);
        return {
          category,
          percentage,
        };
      });

    return topKeywords;
  };

  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#00E396", "#008FFB"],
    xaxis: {
      categories: [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ],
    },
    yaxis: {
      labels: {
        formatter: (value) => `${value.toLocaleString()} 원`,
      },
    },
    tooltip: {
      y: {
        formatter: (value) => `${value.toLocaleString()} 원`,
      },
    },
    legend: {
      position: "bottom",
    },
  };

  const getColor = (percentage) => {
    if (percentage >= 25) return "#8B95F6"; // 퍼센트 40 이상
    if (percentage >= 20) return "#619af4"; // 퍼센트 30 이상
    if (percentage >= 15) return "#B5CCFF"; // 퍼센트 30 이상
    if (percentage >= 6) return "rgba(255, 161, 115, 0.59)";
    if (percentage >= 5) return "rgba(139, 149, 246, 0.21)"; // 퍼센트 30 이상
    // 퍼센트 20 이상
    return "rgba(139, 149, 246, 0.21)"; // 그 외
  };

  return (
    <div className="myPage-container">
      <div>
        <div className="profile-card">
          <div className="profile-image">
            <Char1 />
          </div>
          <div className="profile-info">
            <h2>{user.name} 님</h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  backgroundColor: "#EDEDEE",
                  color: "#898989",
                  border: "none",
                  padding: "7px",
                  borderRadius: "7px",
                  fontFamily: "Pretendard-Regular",
                  width: "100px",
                  margin: "10px",
                  textAlign: "center",
                }}
              >
                내 정보 수정
              </button>
            </div>
            <div className="info-row">
              <span className="info-row-subject">로그인 ID</span>
              <span>{user.id}</span>
            </div>
            <div className="info-row">
              <span className="info-row-subject">최근 접속시간</span>
              <span style={{ textAlign: "right" }}>
                {lastLoginTime || "접속 시간이 없습니다."}
              </span>
            </div>
            <div className="info-row">
              <span className="info-row-subject">투자자 성향</span>
              <span>
                {profileName ? (
                  profileName
                ) : (
                  <button
                    className="invest-analysis-register-button"
                    onClick={() => navigate("/investAnalysis")}
                  >
                    등록하기
                  </button>
                )}
              </span>
            </div>
            <div className="info-row">
              <span className="info-row-subject">휴대폰 번호</span>
              <span>{formatPhoneNumber(user.phoneNumber)}</span>
            </div>
            <div className="info-row">
              <span className="info-row-subject">이메일 주소</span>
              <span>{user.email}</span>
            </div>
            <div className="info-row">
              <span className="info-row-subject">집 주소</span>
              <span>{user.address}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="asset-status">
        <div className="asset-body">
          <div className="total-asset-graph">
            <div className="total-asset">
              <span style={{ fontFamily: "Pretendard-Bold", fontSize: "17px" }}>
                2024년 총 결제금액
              </span>
              <div className="asset-toggle">
                금액보이기{" "}
                <div>
                  <input
                    type="checkbox"
                    id="asset-toggle"
                    checked={isAssetsVisible}
                    onChange={handleToggle}
                    hidden
                  />
                  <label htmlFor="asset-toggle" className="switchContainer">
                    <span className="switchButton"></span>
                  </label>
                </div>
              </div>
              <div className="asset-amount">
                <span style={{ fontSize: isAssetsVisible ? "22px" : "15px" }}>
                  {isAssetsVisible
                    ? `${total2024.toLocaleString()} 원`
                    : "금액 숨김"}
                </span>
                {isAssetsVisible && (
                  <ArrowRight style={{ marginLeft: "5px" }} />
                )}
              </div>
            </div>
            <span
              style={{
                fontSize: "14px",
                fontFamily: "Pretendard-SemiBold",
                marginLeft: "10px",
              }}
            >
              {total2024 > total2023 ? (
                <>
                  2023년 대비 {(total2024 - total2023).toLocaleString()}원{" "}
                  <span style={{ color: "red" }}>▲</span>
                </>
              ) : (
                <>
                  2023년 대비 {(total2023 - total2024).toLocaleString()}원{" "}
                  <span style={{ color: "blue" }}>▼</span>
                </>
              )}
            </span>

            <span
              style={{
                fontSize: "14px",
                fontFamily: "Pretendard-SemiBold",
                marginTop: "5px",
                marginLeft: "10px",
              }}
            >
              {`또래 ${percentile}`}
            </span>

            <ReactApexChart
              options={chartOptions}
              series={chartSeries}
              type="line"
              height={350}
            />
          </div>

          <div className="consumption-keywords">
            <span style={{ fontFamily: "Pretendard-Bold", fontSize: "17px" }}>
              {user.name} 님의 소비 카테고리
            </span>
            <div className="keywords-bubble">
              {keywords.map((keyword, index) => {
                const size = 80 + parseInt(keyword.percentage); // 기본 50px에 퍼센트 값 추가
                return (
                  <span
                    key={index}
                    className="bubble"
                    style={{
                      backgroundColor: getColor(keyword.percentage),
                      width: `${size}px`,
                      height: `${size}px`,

                      margin: "5px",
                    }}
                  >
                    {`${keyword.category} ${keyword.percentage}%`}
                  </span>
                );
              })}
            </div>
          </div>
        </div>

        <div className="weekly-consumption-container">
          <div className="weekly-consumption-box">
            <span style={{ fontFamily: "Pretendard-Bold", fontSize: "17px" }}>
              주간 소비
            </span>
            <div className="date-navigation">
              <ArrowLeft onClick={() => handleMonthChange(-1)} />
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>
                  {`24년 ${selectedMonth + 1}월`} <Cal />
                </span>
              </div>
              <ArrowRight
                style={{ width: "24px", height: "24px" }}
                onClick={() => handleMonthChange(1)}
              />
            </div>
            <h5>{`1일부터 오늘까지`}</h5>
            <h4>{`${monthlyTotal.toLocaleString()}원 소비했어요`}</h4>
            {weeklyData.map((weekData, index) => (
              <div
                key={index}
                className={`weekly-item ${
                  selectedWeekIndex === index ? "selected" : ""
                }`}
                onClick={() => handleWeekClick(index)}
              >
                <div className="week-info">
                  <div className="week-title">{weekData.week}</div>
                  <div className="week-dates">{weekData.dateRange}</div>
                </div>
                <div className="week-status">
                  <div
                    className={`status ${weekData.increase ? "increase" : ""}`}
                  >
                    {weekData.increase ? "▲ 증가" : "▼ 감소"}
                  </div>
                  <div className="week-amount">
                    {weekData.amount.toLocaleString()} 원
                  </div>
                </div>
                {weekData.comparison && (
                  <div className="comparison-bubble-wrapper">
                    <div className="comparison-bubble">
                      {weekData.comparison}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="weekly-consumption-chart">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={pieChartData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  innerRadius={30}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {pieChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>

            <div className="consumption-details">
              {pieChartData.map((entry, index) => {
                const percentage = Math.round(
                  (entry.value / totalAmount) * 100
                );
                return (
                  <div key={index} className="consumption-item">
                    <div
                      className="color-circle"
                      style={{ backgroundColor: COLORS[index] }}
                    ></div>
                    <span>{entry.name}</span>
                    <span style={{ color: "#D6D6D6", marginRight: "100px" }}>
                      {percentage}%
                    </span>
                    <span>{entry.value.toLocaleString()} 원</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCardReport;
