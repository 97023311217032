class MessageParser {
  constructor(actionProvider, state) {
    this.actionProvider = actionProvider;
    this.state = state;
  }

  parse(input) {
    if (typeof input === "string") {
      // 사용자가 텍스트를 입력한 경우
      console.log("사용자 입력:", input);
      this.actionProvider.handleMessage(input);
    } else if (input instanceof File) {
      // 사용자가 이미지를 업로드한 경우
      console.log("이미지 파일 업로드:", input);
      this.actionProvider.handleImageUpload(input);
    }
  }
}

export default MessageParser;
